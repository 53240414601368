/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { ChangeEvent, isValidElement } from 'react'
import { jsx } from '@emotion/core'
import { Box } from 'reflexbox'
import styled from 'themes'
import {
  border,
  BorderProps,
  space as spaceStyled,
  SpaceProps,
  typography,
  TypographyProps,
  LayoutProps,
  variant,
  VariantArgs,
} from 'styled-system'
import css from '@styled-system/css'
import { Typography } from 'components/data-display/typography'

export type SwitchBaseSize = 'default' | 'large'
export type SwitchBaseProps = VariantArgs &
  BorderProps &
  TypographyProps &
  SpaceProps &
  LayoutProps & {
    type?: 'checkbox' | 'radio'
    label?: string | React.ReactElement
    hint?: string | React.ReactElement
    size?: SwitchBaseSize
    disabled?: boolean
    focused?: boolean
    checked?: boolean
    name?: string
    value?: string | number
    icon?: React.ReactElement
    onChange?: (value: ChangeEvent<HTMLInputElement>) => void
  }

export const getSwitchBaseSize = (size: SwitchBaseSize = 'default'): number =>
  size === 'large' ? 24 : 20

const SwitchBaseStyled = styled(Box)<
  SwitchBaseProps & { variant?: 'default' | 'checked' | 'disabled' }
>(
  border,
  spaceStyled,
  typography,
  ({ size, theme: { addAlpha, colors, isDark } }) =>
    css({
      display: 'inline-flex',
      label: {
        display: 'flex',
        cursor: 'pointer',
      },
      '.switch-base-icon': {
        width: getSwitchBaseSize(size),
        height: getSwitchBaseSize(size),
        border: 2,
        borderStyle: 'solid',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        borderColor: 'backgrounds.accent',
        '>div': {
          position: 'absolute',
          display: 'none',
        },
      },
      '.switch-base-input': {
        position: 'relative',
        transition: 'box-shadow .35s ease',
        borderRadius: 'default',
        input: {
          opacity: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          padding: 0,
          margin: 0,
          cursor: 'pointer',
          zIndex: 10,
        },
        '&:focus-within,  &.focused': {
          transition: 'box-shadow .2s ease',
          boxShadow: `0px 0px 0px 2px ${addAlpha(
            colors.blue[200],
            isDark ? 0.32 : 0.24,
          )}`,
        },
      },
    }),
  variant({
    variants: {
      default: {
        '.switch-base-icon': {
          borderColor: 'fills.tertiary',
        },
      },
      checked: {
        '.switch-base-icon': {
          borderColor: 'backgrounds.accent',
          '>div': {
            display: 'flex',
          },
        },
      },
      disabled: {
        label: {
          cursor: 'default',
        },
        '.switch-base-icon': {
          color: 'fills.primary',
          borderColor: 'fills.primary',
        },
        '.switch-base-input': {
          input: {
            cursor: 'default',
          },
        },
      },
    },
  }),
)

const Label: React.FC<SwitchBaseProps> = ({ size, disabled, label }) => (
  <Box
    ml={size === 'large' ? 3 : 2}
    minWidth="auto"
    color={`content.${disabled ? 'tertiary' : 'primary'}`}
  >
    {isValidElement(label) ? (
      label
    ) : (
      <Typography
        variant={size === 'large' ? 'body1' : 'body2'}
        color="inherit"
      >
        {label}
      </Typography>
    )}
  </Box>
)

const Hint: React.FC<SwitchBaseProps> = ({ size, disabled, hint }) => (
  <Box display="flex" mt={1}>
    <Box minWidth="auto" width={getSwitchBaseSize(size)} />
    <Box
      ml={size === 'large' ? 3 : 2}
      color={`content.${disabled ? 'tertiary' : 'secondary'}`}
    >
      {isValidElement(hint) ? (
        hint
      ) : (
        <Typography variant="caption" color="inherit">
          {hint}
        </Typography>
      )}
    </Box>
  </Box>
)

const SwitchBase: React.FC<SwitchBaseProps> = ({
  size = 'default',
  type = 'checkbox',
  checked = false,
  label,
  hint,
  onChange,
  disabled,
  value,
  name,
  icon,
  focused,
  ...other
}) => (
  <SwitchBaseStyled
    variant={(disabled && 'disabled') || (checked && 'checked') || 'default'}
    {...{ checked, size, disabled }}
    {...other}
  >
    <label>
      <Box
        className={`switch-base-input${focused ? ' focused' : ''}`}
        minWidth="auto"
        width={getSwitchBaseSize(size)}
        height={getSwitchBaseSize(size)}
      >
        <input
          checked={checked}
          {...{ name, value, disabled, onChange, type }}
        />
        <Box className="switch-base-icon">{icon}</Box>
      </Box>
      {label && <Label {...{ size, disabled, label }} />}
    </label>
    {hint && <Hint {...{ size, disabled, hint }} />}
  </SwitchBaseStyled>
)

export default SwitchBase
