/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import React, {
  memo,
  useMemo,
  useRef,
  useState,
  useEffect,
  HTMLAttributes,
} from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import { Icon } from 'components/data-display/icon'
import { grid, GridProps } from 'styled-system'
import css from '@styled-system/css'
import { useDndReordering } from '../../hooks'
import {
  DataTableRowProps,
  DataTableField,
  DataTableEmptyRowProps,
} from '../data-table.types'
import { DataTableCell } from '../data-table-cell'

const DataTableRowStyled = styled('li')<
  GridProps & {
    reorderable?: boolean
    fieldsLength?: number
    collapsed?: boolean
    isParentCollapsed?: boolean
    endAdornment?: boolean
    height?: string | number
  }
>(
  grid,
  ({
    collapsed = false,
    isParentCollapsed,
    reorderable = false,
    endAdornment = false,
    fieldsLength,
    height,
    theme: {
      colors: { blue },
    },
  }) =>
    css({
      display: !isParentCollapsed && collapsed ? 'none' : 'grid',
      gridTemplateColumns: `${
        reorderable ? 'max-content' : ''
      } repeat(${fieldsLength}, minmax(200px, 1fr)) ${
        endAdornment ? 'max-content' : ''
      }`,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'borders.default',
      padding: '6px 0',
      '.placeholder': {
        color: 'content.tertiary',
        alignItems: 'center',
        height,
      },
      '.adornment': {
        // width: 24, // @TODO: need to add fit-content support
        alignSelf: 'center',
        paddingLeft: 0,
        boxSizing: 'content-box',
        paddingRight: 3,
        '&>div': {
          visibility: 'hidden',
          cursor: 'move',
        },
        '&:last-of-type': {
          paddingRight: 0,
        },
      },
      '&.expanded': {
        paddingBottom: 6,
      },
      '&.dropArea': {
        position: 'relative',
        '&::before': {
          content: '""',
          width: '100%',
          height: '2px',
          position: 'absolute',
          backgroundColor: blue[200],
        },
        '&.placeholder-top::before': { top: 0 },
        '&.placeholder-bottom::before': { bottom: 0 },
      },
      '&:hover': {
        '.adornment': {
          '&>div': {
            visibility: 'visible',
          },
        },
      },
    }),
)

export const DataTableEmptyRow: React.FC<DataTableEmptyRowProps> = ({
  fieldsLength,
  reorderable,
  isParentCollapsed,
  lastItemIndex,
  lastRowPlaceholdersWithKey,
  depth,
  lastRow,
  parentId,
  hasCheckbox,
  isEndAdornment,
  onSetFocusedItemCellIndex,
}) => (
  <DataTableRowStyled
    draggable={false}
    fieldsLength={fieldsLength}
    isParentCollapsed={isParentCollapsed}
    data-idx={lastItemIndex}
    endAdornment={isEndAdornment}
    height={lastRow?.height ?? 32}
    reorderable={reorderable}
  >
    {reorderable && <DataTableCell className="adornment" css={{ width: 20 }} />}
    {lastRowPlaceholdersWithKey?.map(
      (rowItemPlaceholder, fieldIndex: number) => (
        <DataTableCell
          key={rowItemPlaceholder.key}
          className="placeholder"
          itemIndex={`${lastItemIndex}.${fieldIndex}`}
          {...{ fieldIndex, depth, hasCheckbox }}
          onClick={() => {
            if (onSetFocusedItemCellIndex) {
              onSetFocusedItemCellIndex(`${lastItemIndex}.${fieldIndex}`)
            }
            lastRow?.onClick(parentId)
          }}
        >
          {rowItemPlaceholder.value}
        </DataTableCell>
      ),
    )}
    {isEndAdornment && (
      <DataTableCell className="adornment" css={{ width: 24 }} />
    )}
  </DataTableRowStyled>
)

// @TODO: prevent showing empty rows (when data is not picked yet)
const DataTableRow = <T, K>({
  depth = 0,
  item,
  collapsed = false,
  fields,
  reorderable,
  onToggle,
  onChangeItemData,
  isParentCollapsed,
  checkboxProps,
  endAdornment,
  lastRow,
  lastRowPlaceholdersWithKey,
  isLastItem,
  focusedItemCellIndex,
  onSetFocusedItemCellIndex,
  itemIndex,
  hasCheckbox,
  parentId,
}: DataTableRowProps<T, K> & HTMLAttributes<HTMLElement>) => {
  const [draggable, setDraggable] = useState(false)
  const ref = useRef<HTMLLIElement>(null)
  const { onDragStart, onDragOver, onDragEnter, onDragLeave, onDragEnd } =
    useDndReordering(ref)

  const [itemToFocus, setItemToFocus] = useState<HTMLElement | undefined>()

  const lastItemIndex = useMemo(() => {
    if (!itemIndex) {
      return ''
    }

    const itemIndexArr = itemIndex?.split('.')
    const resultIndex =
      itemIndexArr.length === 1
        ? String(Number(itemIndexArr) + 1)
        : itemIndexArr.reduce(
            (idx = '', val, index, { length }: { length: number }) =>
              `${idx}.${index === length - 1 ? Number(val) + 1 : val}`,
          )

    return resultIndex
  }, [itemIndex])

  useEffect(() => {
    const row = ref.current
    if (isLastItem && focusedItemCellIndex !== undefined && row) {
      setTimeout(() => {
        setItemToFocus(
          row
            .querySelector(`div[data-idx="${focusedItemCellIndex}"]`)
            ?.querySelector('[tabindex]') as HTMLElement,
        )
      }, 100)
    }
  }, [isLastItem, focusedItemCellIndex, ref])

  useEffect(() => {
    if (itemToFocus) {
      itemToFocus.focus()
    }
  }, [itemToFocus])

  const isLastRow =
    isLastItem && lastRow && lastRowPlaceholdersWithKey && !lastRow.topLevelOnly

  return (
    <React.Fragment>
      <DataTableRowStyled
        className={item.group ? 'group' : undefined}
        collapsed={collapsed}
        data-id={item.uuid}
        data-idx={itemIndex}
        draggable={draggable}
        endAdornment={Boolean(endAdornment)}
        fieldsLength={fields.length}
        isParentCollapsed={isParentCollapsed}
        reorderable={reorderable}
        ref={ref}
        {...(reorderable
          ? { onDragStart, onDragOver, onDragEnter, onDragLeave, onDragEnd }
          : undefined)}
      >
        {reorderable && (
          // @TODO fix a11y issue below
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <DataTableCell
            className="adornment"
            css={{ width: 20 }}
            // We should avoid permanently enabled draggable property
            // because it leads to incorrect behavior (focusNode is null) of window.getSelection method
            onMouseOver={() => {
              if (!draggable) {
                setDraggable(true)
              }
            }}
            onMouseOut={() => {
              if (draggable) {
                setDraggable(false)
              }
            }}
          >
            <Icon size={20} symbol="drag" color="content.tertiary" />
          </DataTableCell>
        )}
        {fields?.map((field, fieldIndex) => (
          <DataTableCell
            key={`${item.uuid}-${field.key}`}
            field={field as DataTableField<T | K>}
            itemIndex={`${itemIndex}.${fieldIndex}`}
            {...{
              checkboxProps,
              collapsed,
              item,
              depth,
              fieldIndex,
              onChangeItemData,
              onToggle,
            }}
          />
        ))}
        {endAdornment && (
          <DataTableCell className="adornment" css={{ width: 24 }}>
            <div>{endAdornment(item)}</div>
          </DataTableCell>
        )}
      </DataTableRowStyled>
      {isLastRow && (!item.children || item.children.length === 0) && (
        <DataTableEmptyRow
          fieldsLength={fields.length}
          {...{
            isParentCollapsed,
            reorderable,
            lastItemIndex,
            lastRowPlaceholdersWithKey,
            depth,
            lastRow,
            parentId,
            hasCheckbox,
            isEndAdornment: Boolean(endAdornment),
            onSetFocusedItemCellIndex,
          }}
        />
      )}
      {isLastRow && depth > 0 && (
        <DataTableEmptyRow
          fieldsLength={fields.length}
          {...{
            isParentCollapsed,
            reorderable,
            lastItemIndex,
            lastRowPlaceholdersWithKey,
            depth: depth - 1,
            lastRow,
            parentId,
            hasCheckbox,
            isEndAdornment: Boolean(endAdornment),
            onSetFocusedItemCellIndex,
          }}
        />
      )}
    </React.Fragment>
  )
}

export default memo(
  DataTableRow,
  (prevProps, nextProps) =>
    Object.keys(prevProps).find(
      (key) =>
        key !== 'item' &&
        prevProps[key as keyof typeof prevProps] !==
          nextProps[key as keyof typeof nextProps],
    ) === undefined &&
    JSON.stringify(prevProps.item.data) === JSON.stringify(nextProps.item.data),
) as typeof DataTableRow
