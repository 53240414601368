/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Box } from 'reflexbox'
import { jsx } from '@emotion/core'
import { Icon } from 'components/data-display/icon'
import { Typography } from 'components/data-display/typography'
import { Menu, MenuItem } from 'components/navigation/menu'
import {
  InputContainer,
  InputContainerInputSizes,
} from 'components/inputs/input-container'
import { Skeleton, SkeletonProps } from 'components/feedback/skeleton'

export type SelectItem = {
  id: string
  label?: string
}

export interface SelectProps<T> {
  disabled?: boolean
  editOnDoubleClick?: boolean
  error?: boolean
  focused?: boolean
  hover?: boolean
  items?: (string | ReactElement)[]
  maxHeight?: number
  placeholder?: string
  skeletonProps?: SkeletonProps
  readOnly?: boolean
  startAdornment?: ReactElement
  value?: string | null
  variant?: InputContainerInputSizes
  selectNone?: boolean
  noneLabel?: string
  width?: number | string
  itemDataSelector: (key?: string | null) => T | undefined | null
  itemRender?: (
    item: T | undefined | null,
    isInMenu: boolean,
  ) => string | ReactElement | undefined
  onChange: (id?: string | null) => void
}

const getSelection = (selected: boolean) =>
  selected ? (
    <Icon symbol="check" size={20} mr={1} />
  ) : (
    <Box width={20} mr={1} />
  )

const Select = <T extends SelectItem>({
  editOnDoubleClick,
  items,
  hover,
  focused,
  readOnly = false,
  maxHeight,
  skeletonProps,
  selectNone,
  noneLabel = 'None',
  value,
  variant,
  width,
  itemDataSelector,
  itemRender = (item) => item?.label,
  onChange,
  ...other
}: SelectProps<T>): JSX.Element => {
  const [anchorHover, setAnchorHover] = useState<Element | null>(null)
  const [anchorMenu, setAnchorMenu] = useState<Element | null>(null)
  const [selectedItem, setSelectedItem] = useState<T | undefined | null>()

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // HACK: Needed to set focus to menu item in portal
    setTimeout(() => {
      if (anchorMenu) {
        const menuItem =
          menuRef.current?.querySelector(
            `[data-value="${selectedItem?.id}"]`,
          ) ?? menuRef.current?.querySelector('[data-role="menu-item"]')

        ;(menuItem as HTMLElement)?.focus()
      }
    }, 0)
  }, [anchorMenu, menuRef, selectedItem])

  useEffect(() => {
    setSelectedItem(itemDataSelector(value))
  }, [value, itemDataSelector])

  const onClick = (event: MouseEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const onClose = useCallback(() => {
    if (anchorMenu) {
      setAnchorHover(null)
    }
    setAnchorMenu(null)
  }, [anchorMenu])

  const onChangeValue = useCallback(
    (id?: string | null) => {
      if (onChange) {
        onChange(id)
      }
      onClose()
    },
    [onChange, onClose],
  )

  const onHoverOpen = (event: MouseEvent) => {
    if (readOnly) {
      return
    }
    setAnchorHover(event.currentTarget)
  }

  const onKeyPress = (event: KeyboardEvent) => {
    const target = event.currentTarget

    if (event.key === 'Enter' && target instanceof Element) {
      event.preventDefault()
      event.stopPropagation()

      setAnchorMenu(target)
    }
  }

  if (selectedItem === undefined) {
    return <Skeleton variant="rect" height={32} {...skeletonProps} />
  }

  return hover && anchorHover === null ? (
    <Typography
      variant={variant === 'large' ? 'body1' : 'body2'}
      onClick={!editOnDoubleClick ? onHoverOpen : undefined}
      onDoubleClick={editOnDoubleClick ? onHoverOpen : undefined}
    >
      {itemRender(selectedItem, false)}
    </Typography>
  ) : (
    <React.Fragment>
      <InputContainer
        css={{
          cursor: 'pointer',
          width: width || 'fit-content',
          outline: '0 none',
          borderStyle: 'none',
        }}
        data-role="select"
        endAdornment={<Icon symbol="caret-down" size={20} ml={1} />}
        focused={Boolean(anchorMenu) || focused}
        representation={hover ? 'hover' : 'default'}
        {...{ onClick, onKeyPress, variant, ...other }}
      >
        {selectedItem === null ? noneLabel : itemRender(selectedItem, false)}
      </InputContainer>
      {anchorMenu && (
        <Menu
          maxHeight={maxHeight}
          popoverProps={{
            anchorEl: anchorMenu,
            open: Boolean(anchorMenu),
            onClose,
          }}
          fullWidth
          ref={menuRef}
        >
          {(selectNone || selectedItem === null) && (
            <MenuItem
              startAdornment={getSelection(selectedItem === null)}
              key="none"
              onSelect={() => onChangeValue(null)}
              disabled={!selectNone}
            >
              {noneLabel}
            </MenuItem>
          )}
          {items?.map((item, index) => {
            if (React.isValidElement(item)) {
              // eslint-disable-next-line react/no-array-index-key
              return <React.Fragment key={index}>{item}</React.Fragment>
            }

            const itemData = itemDataSelector(item)
            return itemData ? (
              <MenuItem
                startAdornment={getSelection(selectedItem?.id === itemData.id)}
                key={itemData.id}
                onSelect={() => onChangeValue(item)}
                data-value={itemData.id}
              >
                {itemRender(itemData, true)}
              </MenuItem>
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index} />
            )
          })}
        </Menu>
      )}
    </React.Fragment>
  )
}

export default Select
