/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { MouseEvent, isValidElement } from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import { IconSymbol } from 'themes/common.types'
import { Icon, Typography } from 'components/data-display'
import { Flex, Box } from 'reflexbox'
import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant as variantStyled,
  VariantArgs,
} from 'styled-system'

export type NotificationVariant = 'error' | 'success' | 'info'
export type NotificationProps = BorderProps &
  LayoutProps &
  SpaceProps &
  VariantArgs & {
    variant?: NotificationVariant
    icon?: IconSymbol
    displayInline?: boolean
    title?: string
    onDismiss?: (event: MouseEvent) => void
  }

const DismissButton = styled.button({
  margin: 0,
  padding: 0,
  cursor: 'pointer',
  border: '0 none',
  backgroundColor: 'transparent',
  outline: '0 none',
  opacity: 0.28,
  '&:hover': {
    opacity: 0.55,
  },
  '&:active, &:focus': {
    outline: '0 none',
    opacity: 1,
  },
  '&, &:hover, &:active': {
    transition: 'opacity .25s cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
})

const Container = styled(Flex)(
  border,
  space,
  layout,
  ({ theme: { colors, addAlpha } }) =>
    variantStyled({
      variants: {
        error: {
          bg: addAlpha(colors.red[200], 0.12),
        },
        info: {
          bg: addAlpha(colors.blue[200], 0.12),
        },
        success: {
          bg: addAlpha(colors.green[200], 0.12),
        },
      },
    }),
)

const iconVariants = {
  error: {
    color: 'content.error',
  },
  success: {
    color: 'content.success',
  },
  info: {
    color: 'content.accent',
  },
}

const Notification: React.FC<NotificationProps> = ({
  variant = 'info',
  displayInline = false,
  width = 'fit-content',
  title,
  children,
  icon,
  onDismiss,
  ...other
}) => (
  <Container
    m={1}
    p={2}
    paddingTop={9}
    borderRadius="default"
    variant={variant}
    width={width}
    minHeight={40}
    {...other}
  >
    {icon && (
      <Icon
        minWidth="auto"
        symbol={icon}
        size={20}
        {...iconVariants[variant]}
      />
    )}
    <Box minWidth="auto" flex={1} pl={icon ? 2 : 1} pr={2}>
      {title && (
        <Typography
          display={displayInline ? 'inline' : undefined}
          variant="body2"
          color="primary"
          fontWeight="bold"
          mr={1}
        >
          {title}
        </Typography>
      )}
      {children &&
        (isValidElement(children) ? (
          children
        ) : (
          <Typography
            variant="body2"
            color="secondary"
            display={displayInline ? 'inline' : undefined}
          >
            {children}
          </Typography>
        ))}
    </Box>
    {onDismiss && (
      <Box minWidth="auto">
        <DismissButton onClick={onDismiss}>
          <Icon symbol="close" size={20} />
        </DismissButton>
      </Box>
    )}
  </Container>
)

export default Notification
