/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import {
  memo,
  useRef,
  useState,
  useEffect,
  MouseEvent,
  useCallback,
} from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import css from '@styled-system/css'
import { Box } from 'reflexbox'
import { IconButton } from 'components/inputs/icon-button'
import { useDndReordering } from '../../hooks'
import { ListRowProps } from '../list-types.types'

const ListRowStyled = memo(
  styled('li')<{
    collapsed?: boolean
    isParentCollapsed?: boolean
    collapsedRoot?: boolean
    hasChildren?: boolean
    depth?: number
  }>(
    ({
      collapsed = false,
      isParentCollapsed = false,
      collapsedRoot = false,
      depth = 0,
      hasChildren = false,
      theme: {
        colors: { blue, common },
        space,
        typography,
      },
    }) =>
      css({
        ...typography.body2,
        display: !isParentCollapsed && collapsed ? 'none' : 'block',
        padding: `6px ${space[3]}px 6px ${
          space[3] + depth * 28 + (hasChildren ? 28 : 0)
        }px`,
        cursor: 'pointer',
        borderRadius: 'default',
        position: 'relative',
        '.collapse': {
          position: 'absolute',
          marginLeft: -28,
          top: '6px',
          minWidth: 'auto',
          cursor: 'pointer',
        },
        '&:hover': {
          backgroundColor: 'fills.quanternary',
          '.menu': {
            display: 'flex',
          },
        },
        '&.selected-parent': {
          color: 'common.white',
          backgroundColor: 'backgrounds.accent',
          borderBottomLeftRadius: collapsedRoot ? 0 : undefined,
          borderBottomRightRadius: collapsedRoot ? 0 : undefined,
          svg: {
            color: 'common.white',
          },
          // TODO: we need something smarter here
          '.white-on-hover': {
            '& span': {
              color: `${common.white} !important`,
            },
            '& [data-role="text-field-update"] span': {
              color: 'initial !important',
            },
          },
        },
        '&.selected-child': {
          backgroundColor: 'backgrounds.info',
          borderRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          position: 'relative',
          zIndex: 10,
          '&.last': {
            borderBottomLeftRadius: 'default',
            borderBottomRightRadius: 'default',
          },
        },
        '&.dropArea': {
          position: 'relative',
          '&::before': {
            content: '""',
            width: '100%',
            height: '2px',
            position: 'absolute',
            backgroundColor: blue[200],
          },
          '&.placeholder-top::before': { top: 0 },
          '&.placeholder-bottom::before': { bottom: 0 },
        },
      }),
  ),
)

const ListRow = <T, K>({
  classNameDropArea,
  collapsed,
  itemRender,
  depth = 0,
  hasChildren,
  item,
  isParentCollapsed,
  onChangeItemData,
  onSelect,
  onToggle,
  parent,
  reorderable,
  selected,
  className: classNameProp,
}: ListRowProps<T, K> & { classNameDropArea?: string }) => {
  const ref = useRef<HTMLLIElement>(null)
  const {
    onDragStart,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDragEnd,
  } = useDndReordering(ref)

  const [value, setValue] = useState<string | React.ReactElement>()
  const defaultValue = useCallback(
    () => (itemRender ? itemRender(item, onChangeItemData) : ''),
    [item, itemRender, onChangeItemData],
  )

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const toggleItems = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      onToggle(item)
    },
    [item, onToggle],
  )

  const onClick = useCallback(
    (event: MouseEvent) => {
      if (collapsed) {
        toggleItems(event)
      }

      if (onSelect) {
        onSelect(item)
      }
    },
    [collapsed, item, onSelect, toggleItems],
  )

  const className = [
    classNameProp,
    classNameDropArea,
    selected,
    item.group ? 'group' : '',
  ]
    .filter((i) => i)
    .join(' ')

  return (
    <ListRowStyled
      data-id={item.uuid}
      data-parent-id={parent?.uuid}
      draggable={reorderable}
      collapsedRoot={hasChildren && !collapsed}
      {...(reorderable
        ? {
            onDragStart,
            onDragOver,
            onDragEnter,
            onDragLeave,
            onDragEnd,
          }
        : undefined)}
      {...{
        ref,
        className,
        collapsed,
        isParentCollapsed,
        depth,
        onClick,
        hasChildren,
      }}
    >
      {hasChildren && (
        <Box className="collapse" onClick={toggleItems}>
          <IconButton
            icon={collapsed ? 'caret-right' : 'caret-down'}
            size="small"
            noBorder
          />
        </Box>
      )}
      {value}
    </ListRowStyled>
  )
}

export default memo(
  ListRow,
  (prevProps, nextProps) =>
    Object.keys(prevProps).find(
      (key) =>
        key !== 'item' &&
        prevProps[key as keyof typeof prevProps] !==
          nextProps[key as keyof typeof nextProps],
    ) === undefined &&
    JSON.stringify(prevProps.item.data) === JSON.stringify(nextProps.item.data),
) as typeof ListRow
