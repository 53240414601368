/**
 * addAlpha
 *
 * @summary
 *  this function takes a hex color and adds alpha channel to it, it also takes colors
 *  with alpha channel and modifies it according to the opacity value passed to it.
 *
 * @param {string} color    - the hex color value
 * @param {number} opacity  - the alpha intensity 0 to 1
 *
 * @returns {string}
 */
const addAlpha = (color: string, opacity: number): string => {
  let output: string

  if (typeof color === 'string' && color.length === 9) {
    output = color.replace(/(\(\d{1,2}|\w{1,2})$/gi, '')
    return addAlpha(output, opacity)
  }

  if (opacity === 0) {
    output = `${color}00`
    return output
  }

  output = `${color}${Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    .toString(16)
    .toUpperCase()}`

  return output
}

export default addAlpha
