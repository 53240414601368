/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/core'
import { LayoutProps, SpaceProps } from 'styled-system'
import Spinner, { SpinnerProps } from './indicator-spinner'
import Linear, { LinearIndicatorProps } from './indicator-linear'

export type ProgressIndicatorProps = LayoutProps &
  SpaceProps &
  SpinnerProps &
  LinearIndicatorProps & {
    type?: 'circular' | 'linear'
    value?: number
    variant?: 'determinate' | 'indeterminate' | 'static'
  }

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  variant = 'indeterminate',
  value = 0,
  size = 'large',
  type = 'circular',
  ...other
}) => (
  <React.Fragment>
    {type === 'linear' ? (
      <Linear {...{ size, value, variant }} {...other} />
    ) : (
      <Spinner {...{ size, value, variant }} {...other} />
    )}
  </React.Fragment>
)

export default ProgressIndicator
