/** @jsxRuntime classic */
/** @jsx jsx */

import { useCallback, useMemo, useState } from 'react'
import { jsx } from '@emotion/core'
import Prompt from 'components/feedback/prompt/prompt'
import { getUuid } from 'utils'
import PromptContext, {
  PromptContextProps,
  PromptShowProps,
  PromptShowFn,
  AlertShowFn,
  AlertShowProps,
} from './prompt-context'

const emptyMap = {}

type PromptItemProps = PromptShowProps & {
  itemKey: string
  removeItem: (key: string) => void
}

const PromptItem: React.FC<PromptItemProps> = ({
  title,
  content,
  onCancel,
  onProceed,
  itemKey,
  removeItem,
  ...props
}) => {
  const onCancelFn = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
    removeItem(itemKey)
  }, [onCancel, removeItem, itemKey])

  const onProceedFn = useCallback(() => {
    if (onProceed) {
      onProceed()
    }
    removeItem(itemKey)
  }, [onProceed, removeItem, itemKey])

  return (
    <Prompt
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title={typeof title === 'function' ? title() : title}
      content={typeof content === 'function' ? content() : content}
      onCancel={onCancelFn}
      onProceed={onProceedFn}
    />
  )
}

const PromptProvider: React.FC = ({ children }) => {
  const [promptProps, setPromptProps] = useState<{
    [key: string]: PromptShowProps
  }>(emptyMap)

  const promptFn: PromptShowFn = useCallback((props: PromptShowProps) => {
    const key = getUuid()
    setPromptProps((oldValue) => ({
      ...oldValue,
      [key]: props,
    }))
  }, [])

  const alertFn: AlertShowFn = useCallback((props: AlertShowProps) => {
    const key = getUuid()
    setPromptProps((oldValue) => ({
      ...oldValue,
      [key]: {
        ...props,
        cancelText: null,
        onCancel: undefined,
      },
    }))
  }, [])

  const value = useMemo(
    (): PromptContextProps => ({
      prompt: promptFn,
      alert: alertFn,
    }),
    [promptFn, alertFn],
  )

  const removeItem = useCallback((key: string) => {
    setPromptProps((map) => {
      const newMap = { ...map }
      // eslint-disable-next-line no-param-reassign
      delete newMap[key]
      return newMap
    })
  }, [])

  return (
    <PromptContext.Provider value={value}>
      {children}
      {Object.entries(promptProps).map(([key, props]) => (
        <PromptItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          itemKey={key}
          removeItem={removeItem}
        />
      ))}
    </PromptContext.Provider>
  )
}

export default PromptProvider
