/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { memo } from 'react'
import { jsx } from '@emotion/core'
import { Box } from 'reflexbox'
import { SpaceProps } from 'styled-system'
import css from '@styled-system/css'
import styled from 'themes'

import { TabItem } from './tab-bar-item'

type TabBarProps = SpaceProps & {
  tabs: { [key: string]: string }
  tabAlign?: string
  variant?: 'tab' | 'segment'
  currentTab: string
  setCurrentTab: (tabValue: string) => void
}

const TabBarMemoStyled = styled(Box)<
  SpaceProps & { tabAlign?: string; variant: 'tab' | 'segment' }
>(({ tabAlign = 'flex-start', variant }) =>
  css({
    justifyContent: tabAlign,
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    /**
     * @todo: update me - https://caniuse.com/flexbox-gap
     *  as of safari <= 14.0 attribute gap is not supported, update these values
     *  accordingly when support for these has been rolled out.
     *
     * rowGap: 3,
     * columnGap: variant === 'tab' ? 4 : 2,
     */

    '& > li': {
      marginRight: variant === 'tab' ? 5 : 3,
      marginBottom: variant === 'tab' ? 2 : 1,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  }),
)

const TabBarMemo: React.FC<TabBarProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
  variant = 'tab',
  ...other
}) => (
  <TabBarMemoStyled as="ul" variant={variant} {...other}>
    {Object.entries(tabs).map(([tabValue, tabName]) => (
      <TabItem
        {...{
          tabName,
          tabValue,
          setCurrentTab,
          variant,
          key: tabValue,
          isCurrent: currentTab === tabValue,
        }}
      />
    ))}
  </TabBarMemoStyled>
)

const TabBar = memo(TabBarMemo)

export default TabBar
