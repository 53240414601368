/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import styled from 'themes'
import css from '@styled-system/css'
import { Box } from 'reflexbox'

const DividerStyled = styled(Box)(
  css({
    height: 1,
    backgroundColor: 'borders.default',
  }),
)

const Divider: React.FC = (props) => <DividerStyled my={2} mx={-2} {...props} />

export default Divider
