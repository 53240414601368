/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Box } from 'reflexbox'
import { SpaceProps } from 'styled-system'

export type DialogContentProps = SpaceProps

const DialogContent: React.FC<DialogContentProps> = ({
  children,
  ...other
}) => (
  <Box
    fontFamily="default"
    fontSize="body2"
    lineHeight="body2"
    letterSpacing="body2"
    color="content.secondary"
    height="100%"
    css={{ position: 'relative' }}
    {...other}
  >
    {children}
  </Box>
)

export default DialogContent
