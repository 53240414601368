/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { MouseEvent, isValidElement, useRef, useState, useEffect } from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import { IconSymbol } from 'themes/common.types'
import {
  color,
  ColorProps,
  border,
  BorderProps,
  space as spaceStyled,
  SpaceProps,
  typography,
  TypographyProps,
  variant as variantStyled,
  VariantArgs,
} from 'styled-system'
import css from '@styled-system/css'
import { Icon } from 'components/data-display/icon'

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'danger'
export type ButtonSize = 'default' | 'large'
export type ButtonProps = ColorProps &
  BorderProps &
  TypographyProps &
  VariantArgs &
  SpaceProps & {
    type?: 'submit' | 'reset' | 'button'
    variant?: ButtonVariant
    size?: ButtonSize
    disabled?: boolean
    focused?: boolean
    startIcon?: IconSymbol | React.ReactElement
    endIcon?: IconSymbol | React.ReactElement
    onClick?: (event: MouseEvent) => void
    hasChildren?: boolean
    loading?: boolean
    height?: number
    width?: number
  }

type PaddingProps = {
  space: number[]
  size?: ButtonSize
  hasChildren?: boolean
}

const getPadding = ({ space, size, hasChildren }: PaddingProps) => {
  const py = size === 'large' ? space[4] / 2 : space[3] / 2
  let px = size === 'large' ? space[4] : space[3]
  if (!hasChildren) {
    px = py
  }
  return `${py}px ${px}px`
}

// @ts-ignore because of ColorProps
const Btn = styled.button<ButtonProps>(
  color,
  border,
  spaceStyled,
  typography,
  {
    position: 'relative',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
    outline: '0 none',
    backgroundClip: 'padding-box',
    borderStyle: 'solid',
    '.container': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
    },
    // @todo: *Help Needed* couldn't find an example on the web... :(
    transition:
      'color .25s cubic-bezier(0.4, 0.0, 0.2, 1), background-color .25s cubic-bezier(0.4, 0.0, 0.2, 1)',
    '&[disabled],&:disabled': {
      cursor: 'not-allowed',
    },
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
  },
  ({ size, theme: { colors, space, addAlpha, isDark }, hasChildren, width }) =>
    css({
      width,
      height: size === 'large' ? 40 : 32,
      padding: getPadding({ space, size, hasChildren }),
      border: 1,
      borderRadius: 'default',
      fontSize: size === 'large' ? 'body1' : 'body2',
      transition: 'box-shadow .35s ease',
      '.spinner': {
        display: 'none',
        position: 'absolute',
        animation: 'spin 0.5s linear infinite',
      },
      '&.loading': {
        '.spinner': {
          display: 'block',
        },
        '.container': {
          visibility: 'hidden',
        },
      },
      '&:focus, &.focused': {
        transition: 'box-shadow .2s ease',
        boxShadow: `0px 0px 0px 2px ${addAlpha(
          colors.blue[200],
          isDark ? 0.32 : 0.24,
        )}`,
      },
      '&[disabled], &:disabled': {
        color: 'content.tertiary',
        borderColor: 'fills.quanternary',
        backgroundColor: 'fills.quanternary',
      },
    }),
  ({ theme: { colors, addAlpha, isDark } }) =>
    variantStyled({
      variants: {
        primary: {
          color: 'white',
          bg: 'backgrounds.accent',
          borderColor: 'backgrounds.accent',
          '&:hover:enabled, &.focused': {
            backgroundColor: addAlpha(colors.blue[200], 0.88),
            borderColor: addAlpha(colors.blue[200], 0.88),
          },
        },
        secondary: {
          color: 'content.accent',
          bg: 'transparent',
          borderStyle: 'solid',
          borderColor: 'borders.accent',
          '&:hover:enabled, &.focused': {
            backgroundColor: addAlpha(colors.blue[200], 0.12),
          },
          '&[disabled], &:disabled': {
            backgroundColor: 'transparent',
          },
        },
        tertiary: {
          color: 'content.primary',
          bg: 'transparent',
          borderColor: 'transparent',
          '&:hover:enabled, &.focused': {
            backgroundColor: 'fills.tertiary',
            borderColor: 'fills.tertiary',
          },
          '&[disabled], &:disabled': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          },
        },
        danger: {
          color: isDark ? 'content.primary' : 'white',
          bg: 'red.200',
          borderColor: 'red.200',
          '&:hover:enabled, &.focused': {
            backgroundColor: addAlpha(colors.red[200], 0.88),
            borderColor: addAlpha(colors.red[200], 0.88),
          },
        },
      },
    }),
)

type IconProps = SpaceProps & {
  icon: IconSymbol | React.ReactElement
  size: ButtonSize
  className?: string
}

const getIcon = ({ icon, size, ...other }: IconProps) =>
  isValidElement(icon) ? (
    icon
  ) : (
    <Icon
      color="inherit"
      symbol={icon}
      size={size === 'large' ? 24 : 20}
      {...other}
    />
  )

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'default',
  type = 'button',
  fontWeight = 'bold',
  disabled = false,
  focused = false,
  startIcon,
  endIcon,
  children,
  onClick,
  loading,
  ...other
}) => {
  const ref = useRef<HTMLButtonElement | null>(null)
  const [width, setWidth] = useState<number | undefined>()

  useEffect(() => {
    if (ref.current) {
      // This a crazy kind of fix for the jumping animation issue ~~in IE6~~ in Safari
      setWidth(Math.ceil(ref.current.getBoundingClientRect().width))
    }
  }, [ref])

  return (
    // @ts-ignore because of ColorProps
    <Btn
      className={[focused && 'focused', loading && 'loading']
        .filter((i) => i)
        .join(' ')}
      hasChildren={children !== undefined}
      {...{ type, variant, size, disabled, fontWeight, onClick, ref, width }}
      {...other}
    >
      <div className="container">
        {startIcon && getIcon({ icon: startIcon, size, mr: children ? 2 : 0 })}
        {children}
        {endIcon && getIcon({ icon: endIcon, size, ml: children ? 2 : 0 })}
      </div>
      {loading && getIcon({ icon: 'spinner', size, className: 'spinner' })}
    </Btn>
  )
}

export default Button
