import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Box, Flex } from 'reflexbox'
import styled from 'themes'
import {
  space as spaceStyled,
  SpaceProps,
  variant as variantStyled,
} from 'styled-system'
import useDialog from 'components/feedback/dialog/use-dialog'
import { Icon } from 'components/data-display/icon'
import { IconSymbol } from 'themes/common.types'

type ColorVariantType = 'default' | 'danger' | 'success'
export type SnackbarPropType = {
  closeButton?: boolean
  duration?: number
  icon?: IconSymbol | undefined
  onClose: () => void
  placement?: 'start' | 'end' | 'center'
  show: boolean
  variant: ColorVariantType
}

type CloseButtonComponent = SpaceProps & {
  onClick: (e?: React.MouseEvent) => void
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const CloseButtonStyled = styled.button<CloseButtonComponent>(spaceStyled, {
  boxSizing: 'border-box',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  border: 0,
  cursor: 'pointer',
  backgroundColor: 'transparent',
})

const CloseButton: React.FC<CloseButtonComponent> = ({ onClick }) => (
  <CloseButtonStyled onClick={onClick}>
    <Icon symbol="close" size={18} />
  </CloseButtonStyled>
)

const SnackbarContainer = styled(Box)<{ variant: ColorVariantType }>(
  ({ theme: { radii, colors, space, shadows } }) => ({
    width: 'auto',
    padding: space[3],
    color: colors.common.white,
    borderRadius: radii.focused,
    boxShadow: shadows.medium,
    '& svg': {
      color: colors.common.white,
    },
    '& button': {
      height: 30,
    },
    '& div, & span': {
      marginLeft: space[1],
      marginRight: space[1],
    },
    '& div + button, & span + button': {
      color: colors.common.white,
      alignSelf: 'flex-end',
      marginLeft: space[2],
    },
    // '& button + button': {
    //   marginLeft: space[3],
    // },
  }),
  ({ theme: { colors } }) =>
    variantStyled({
      variants: {
        default: {
          backgroundColor: colors.darkGray[50],
        },
        danger: {
          backgroundColor: colors.content.error,
        },
        success: {
          backgroundColor: colors.content.success,
        },
      },
    }),
)

const Snackbar: React.FC<SnackbarPropType> = ({
  closeButton = false,
  duration = 2500,
  icon = undefined,
  onClose,
  placement = 'center',
  show = false,
  variant = 'default',
  children,
}) => {
  const getPlacement = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
  }
  const elementRef = document.createElement('div') as HTMLDivElement
  const { portalRoot, isVisible, ref } = useDialog({
    elementRef,
    open: show,
    onClose,
    disableBackdropClick: true,
  })

  useEffect(() => {
    if (!isVisible || duration === 0) {
      return undefined
    }

    const timer = setTimeout(() => {
      if (!isVisible) {
        return
      }

      if (onClose) {
        onClose()
      }
    }, duration)
    return () => clearTimeout(timer)
  }, [duration, isVisible, onClose])

  if (!isVisible) {
    return null
  }

  return createPortal(
    <Flex
      justifyContent={getPlacement[placement]}
      alignItems="center"
      p={4}
      css={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9000,
        width: '100%',
      }}
    >
      <SnackbarContainer
        ref={ref}
        variant={variant}
        maxWidth="90%"
        overflowX="hidden"
      >
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          minHeight={28}
          flexWrap="nowrap"
        >
          {icon && <Icon symbol={icon} size={20} />}
          {children}
          {closeButton && <CloseButton onClick={onClose} />}
        </Flex>
      </SnackbarContainer>
    </Flex>,
    portalRoot,
  )
}

export default Snackbar
