/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { KeyboardEvent, ReactElement, useCallback, MouseEvent } from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import css from '@styled-system/css'
import { Box } from 'reflexbox'
import { Typography } from 'components/data-display/typography'

export type MenuItemProps = {
  description?: string | ReactElement
  onSelect?: () => void
  startAdornment?: ReactElement
  endAdornment?: ReactElement
  disabled?: boolean
}

const MenuItemStyled = styled(Box)<{ disabled?: boolean }>(
  ({
    theme: {
      isDark,
      addAlpha,
      colors: { common },
    },
    disabled,
  }) =>
    css({
      display: 'flex',
      alignItems: 'flex-start',
      cursor: 'pointer',
      margin: 0,
      backgroundColor: `backgrounds.${isDark ? 'tertiary' : 'primary'}`,
      color: isDark
        ? addAlpha(common.white, disabled ? 0.57 : 0.87)
        : `content.${disabled ? 'secondary' : 'primary'}`,
      padding: 2,
      fontFamily: 'default',
      fontSize: 'body2',
      lineHeight: 'body2',
      letterSpacing: 'body2',
      minWidth: 'auto',
      whiteSpace: 'nowrap',
      svg: {
        color: 'inherit',
      },
      '&>div': {
        outline: '0 none',
      },
      '&:hover, &:focus': {
        outline: '0 none',
        backgroundColor: 'backgrounds.accent',
        borderRadius: 'default',
        color: addAlpha(common.white, 0.87),
      },
    }),
)

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  onSelect,
  startAdornment,
  endAdornment,
  description,
  ...other
}) => {
  const onKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter' && onSelect) {
      onSelect()
    }
  }
  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      event.preventDefault()
      if (onSelect) {
        onSelect()
      }
    },
    [onSelect],
  )

  return (
    <MenuItemStyled
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      data-role="menu-item"
      {...other}
    >
      {startAdornment}
      <Box tabIndex={-1}>
        {children}
        {description && (
          <Typography variant="caption" color="inherit">
            {description}
          </Typography>
        )}
      </Box>
      {endAdornment}
    </MenuItemStyled>
  )
}

export default MenuItem
