const gray = {
  10: '#F4F4F5',
  50: '#ECEDEE',
  100: '#E2E2E4',
  200: '#D7D7DB',
  300: '#C7C8CC',
  400: '#ACADB4',
  500: '#94969E',
}

export default gray
