/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { KeyboardEvent, forwardRef, ReactNode } from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import css from '@styled-system/css'
import { Elevation } from 'components/data-display/elevation'
import { Popover, PopoverProps } from 'components/data-display/popover'

export type MenuProps = {
  children?: ReactNode | ReactNode[] | string
  fullWidth?: boolean
  maxHeight?: number
  popoverProps: PopoverProps
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
}

const MenuStyled = styled(Elevation)<{
  fullWidth?: boolean
  maxHeight?: number
}>(({ theme: { isDark }, fullWidth, maxHeight }) =>
  css({
    backgroundColor: `backgrounds.${isDark ? 'tertiary' : 'primary'}`,
    margin: 0,
    padding: 2,
    width: fullWidth ? '100%' : 'fit-content',
    maxHeight,
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
)

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  (
    { children, fullWidth = false, maxHeight = 210, popoverProps, ...other },
    forwardedRef,
  ) => {
    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault()
      event.stopPropagation()

      if (event.key === 'ArrowDown') {
        const nextElement = document.activeElement?.nextSibling as HTMLElement
        if (nextElement) {
          nextElement.focus()
        }
      }
      if (event.key === 'ArrowUp') {
        const prevElement = document.activeElement
          ?.previousSibling as HTMLElement
        if (prevElement) {
          prevElement.focus()
        }
      }

      if (event.key === 'Escape' && popoverProps.onClose) {
        popoverProps.onClose()
      }
    }

    return (
      <Popover {...popoverProps}>
        <MenuStyled
          data-role="menu"
          ref={forwardedRef}
          variant="large"
          {...{ fullWidth, onKeyDown, maxHeight, ...other }}
        >
          {children}
        </MenuStyled>
      </Popover>
    )
  },
)

export default Menu
