/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import css from '@styled-system/css'
import { LinkProps, useHistory } from 'react-router-dom'
import React, { useCallback } from 'react'
import styled from 'themes'
import { IconSymbol, ContentColors } from 'themes/common.types'
import {
  color,
  ColorProps,
  layout,
  space,
  variant as variantStyled,
  VariantArgs,
} from 'styled-system'
import LinkContent from './link-content'

export type AnchorTargetProps = '_blank' | '_parent' | '_self'
export type AnchorProps = ColorProps &
  VariantArgs & {
    size?: 'default' | 'large'
    variant?: ContentColors
    link?: LinkProps<string>
    href?: string
    isLinkActive?: boolean
    disabled?: boolean
    target?: AnchorTargetProps
    displayInline?: boolean
    prefix?: IconSymbol
    suffix?: IconSymbol
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
    download?: boolean | string
  }

const StyledAnchor = styled.a(
  color,
  space,
  layout,
  ({ theme: { colors, addAlpha }, isLinkActive, disabled }) =>
    css({
      '&, & svg, & [color="primary"], & [color="secondary"]': {
        color: disabled && addAlpha(colors.content.tertiary, 0.28),
        textDecoration: isLinkActive && !disabled ? 'underline' : 'none',
        cursor: !disabled ? 'pointer' : 'not-allowed',
        // verticalAlign: 'middle',
      },
      '&:hover [color="primary"], &:hover [color="secondary"]': {
        textDecoration: 'underline',
      },
    }),
  variantStyled({
    variants: {
      primary: {
        '&, & svg, & [color="primary"]': {
          color: 'content.accent',
        },
      },
      secondary: {
        '&, & svg, & [color="secondary"]': {
          color: 'inherit',
        },
      },
    },
  }),
)

const A: React.FC<AnchorProps> = ({
  size = 'default',
  variant = 'primary',
  target = '_self',
  displayInline = true,
  isLinkActive = false,
  disabled = false,
  href,
  link,
  prefix,
  suffix,
  children,
  onClick,
  ...other
}) => {
  const history = useHistory()
  const clickHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        event.preventDefault()
      } else if (link && typeof link.to === 'string') {
        event.preventDefault()
        event.stopPropagation()
        history.push(link.to)
      }
    },
    [disabled, history, link],
  )

  const Content = (
    <LinkContent {...{ variant, size, prefix, suffix }} {...other}>
      {children}
    </LinkContent>
  )

  return (
    <StyledAnchor
      display={displayInline ? 'inline-block' : 'block'}
      isLinkActive={isLinkActive ? 1 : 0}
      onClick={onClick || clickHandler}
      {...{ disabled, href, target, variant }}
    >
      {Content}
    </StyledAnchor>
  )
}

export default A
export type { LinkProps }
