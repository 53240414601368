/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { MouseEvent } from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import { IconSymbol } from 'themes/common.types'
import { space as spaceStyled, SpaceProps } from 'styled-system'
import css from '@styled-system/css'
import { Icon } from 'components/data-display/icon'

export type IconButtonSize = 'small' | 'medium' | 'large'
export type IconButtonProps = SpaceProps & {
  type?: 'submit' | 'reset' | 'button'
  size?: IconButtonSize
  icon?: IconSymbol
  disabled?: boolean
  focused?: boolean
  noBorder?: boolean
  color?: string
  onClick?: (event: MouseEvent) => void
}

const Btn = styled.button<IconButtonProps>(
  spaceStyled,
  {
    margin: 0,
    position: 'relative',
    boxSizing: 'border-box',
    outline: '0 none',
    backgroundClip: 'padding-box',
    borderStyle: 'solid',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({
    size = 'medium',
    color,
    disabled,
    noBorder = false,
    theme: { colors, addAlpha, isDark },
  }) =>
    css({
      cursor: disabled ? 'default' : 'pointer',
      padding: { small: 1, medium: '6px', large: 2 }[size],
      border: 0,
      borderRadius: 'default',
      bg: 'transparent',
      '&:hover:enabled, &.focused': {
        transition: 'background-color .2s ease',
        backgroundColor: noBorder ? undefined : 'backgrounds.secondary',
      },
      transition: 'box-shadow .35s ease',
      svg: { color },
      '&:focus, &.focused': {
        transition: 'box-shadow .2s ease',
        boxShadow: `0px 0px 0px 2px ${addAlpha(
          colors.blue[200],
          isDark ? 0.32 : 0.24,
        )}`,
      },
      '&[disabled], &:disabled': {
        svg: {
          color: 'content.tertiary',
        },
      },
    }),
)

const IconButton: React.FC<IconButtonProps> = ({
  size = 'medium',
  type = 'button',
  disabled = false,
  focused = false,
  icon = 'search',
  ...other
}) => (
  <Btn
    className={focused ? 'focused' : undefined}
    {...{ type, size, disabled }}
    {...other}
  >
    <Icon size={{ small: 16, medium: 20, large: 24 }[size]} symbol={icon} />
  </Btn>
)

export default IconButton
