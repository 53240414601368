/** @jsxRuntime classic */
/** @jsx */

import React from 'react'
import styled, { Theme } from 'themes'
import css, { CssFunctionReturnType } from '@styled-system/css'
import { DynamicFieldInputSizes } from '../dynamic-field.types'

export const dynamicFieldVariableStyle = ({
  variant,
  theme: { typography, addAlpha, colors },
  isAnchor,
}: {
  variant?: DynamicFieldInputSizes
  theme: Theme
  isAnchor?: boolean
}): CssFunctionReturnType =>
  css({
    zIndex: 10,
    ...typography[variant === 'large' ? 'body1' : 'body2'],
    position: 'relative',
    whiteSpace: 'nowrap',
    px: 2,
    display: 'inline-flex',
    outline: '0 none',
    backgroundClip: 'padding-box',
    minWidth: '1ch',
    color: 'content.accent',
    alignItems: 'center',
    height: variant === 'large' ? '24px' : '20px',
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: variant === 'large' ? '24px' : '20px',
      borderRadius: 'tag',
      bg: addAlpha(isAnchor ? colors.gray[500] : colors.backgrounds.info, 0.12),
      zIndex: -1,
      left: 0,
      boxSizing: 'content-box',
      top: 0,
    },
  })

const DynamicFieldVariableStyled = styled('span')(dynamicFieldVariableStyle)

const DynamicFieldVariable: React.FC<{
  variant?: DynamicFieldInputSizes
}> = ({ children, variant }) => (
  <DynamicFieldVariableStyled variant={variant}>
    {children}
  </DynamicFieldVariableStyled>
)

export default DynamicFieldVariable
