/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { IconSymbol, ContentColors } from 'themes/common.types'
import React, { isValidElement } from 'react'
import { Icon } from 'components/data-display/icon'
import { Typography } from 'components/data-display/typography'
import { ButtonSize } from 'components/inputs/button/button'
import { SpaceProps } from 'styled-system'

type ChildIconProp = SpaceProps & {
  icon: IconSymbol | React.ReactElement
  size: ButtonSize
}

type LinkTypographyProps = SpaceProps & {
  children: string | React.ReactNode | React.ReactElement
  variant: ContentColors
  size: ButtonSize
}

export type LinkContentProps = SpaceProps & {
  variant: ContentColors
  size: 'default' | 'large'
  prefix?: IconSymbol
  suffix?: IconSymbol
}

const getIcon: React.FC<ChildIconProp> = ({ icon, size, ...other }) =>
  isValidElement(icon) ? (
    icon
  ) : (
    <Icon
      color="inherit"
      symbol={icon}
      size={size === 'large' ? 24 : 20}
      display="inline-block"
      {...other}
    />
  )

const getTypography: React.FC<LinkTypographyProps> = ({
  children,
  size,
  variant,
  ...other
}) =>
  isValidElement(children) ? (
    children
  ) : (
    <Typography
      color={variant}
      display="inline-block"
      lineHeight={size === 'large' ? 'body1' : 'body1'}
      variant={size === 'large' ? 'body1' : 'body2'}
      {...other}
    >
      {children}
    </Typography>
  )

const LinkContent: React.FC<LinkContentProps> = ({
  variant,
  size,
  prefix,
  suffix,
  children,
  ...other
}) => (
  <React.Fragment>
    {prefix && getIcon({ icon: prefix, size, mr: size === 'large' ? 2 : 1 })}
    {getTypography({ children, variant, size, ...other })}
    {suffix && getIcon({ icon: suffix, size, ml: size === 'large' ? 2 : 1 })}
  </React.Fragment>
)

export default LinkContent
