/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useState, useCallback, useEffect, useRef, memo } from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import { layout, LayoutProps } from 'styled-system'
import { useDndReordering } from '../hooks'
import { ListRenderItem, ListComponent, ListItem } from './list-types.types'
import { ListRow } from './list-row'

const ListStyled = styled('ul')<LayoutProps>(
  layout,
  ({
    theme: {
      colors: { blue },
      space,
    },
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
    '&.dragging': {
      li: {
        '&.dragged-to': {
          position: 'relative',
          '&::before': {
            content: '""',
            width: '100%',
            height: '2px',
            position: 'absolute',
            backgroundColor: blue[200],
            top: 0,
          },
          '&.inside': {
            '&::before': {
              top: 'unset',
              bottom: 0,
              marginLeft: space[3],
            },
          },
          '&.last-item': {
            '&::before': {
              top: 'unset',
              bottom: 0,
            },
          },
        },
        '&.dragged-from': {
          '&::before': {
            content: 'unset',
          },
          '& ~.dragged-to::before': {
            top: 'unset',
            bottom: 0,
          },
        },
      },
    },
  }),
)

const RenderItem = <T, K>({
  item,
  depth = 0,
  parent,
  collapsed: collapsedProp = false,
  selected: selectedProp,
  itemDataSelector,
  collapsedIds,
  selectedListItem,
  ...other
}: ListRenderItem<T, K>): JSX.Element | null => {
  const itemData = itemDataSelector ? itemDataSelector(item.data) : item

  const collapsed = collapsedProp || collapsedIds.includes(item.uuid)

  const selected =
    (selectedListItem === item.uuid && 'selected-parent') ||
    (selectedProp === 'selected-parent' && 'selected-child') ||
    selectedProp ||
    undefined

  if (!itemData) {
    return null
  }

  return (
    <React.Fragment>
      <ListRow
        hasChildren={itemData.children && itemData.children.length > 0}
        {...{
          collapsed,
          depth,
          item: itemData,
          isParentCollapsed: collapsedIds.includes(item.uuid) && !collapsedProp,
          parent,
          selected,
          ...other,
        }}
      />
      {(itemData.children || []).map((nestedItem: ListItem<T | K>) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <RenderItemMemo
          key={nestedItem.uuid}
          {...{
            collapsed,
            collapsedIds,
            itemDataSelector,
            depth: depth + 1,
            item: nestedItem as ListItem<T>,
            parent: itemData as ListItem<T>,
            selected,
            selectedListItem,
            ...other,
          }}
        />
      ))}
    </React.Fragment>
  )
}

const RenderItemMemo = memo(RenderItem) as typeof RenderItem

const List = <T, K>({
  items,
  selectedListItem: initSelectedListItem,
  onChangeOrder,
  onSelect,
  ...other
}: ListComponent<T, K>): JSX.Element => {
  const [selectedListItem, setSelectedListItem] = useState<string | undefined>()
  const [collapsedIds, setCollapsedIds] = useState<string[]>([])
  const ref = useRef<HTMLUListElement>(null)
  const { onDragStartList, onDropList } = useDndReordering(ref, onChangeOrder)

  useEffect(() => {
    if (initSelectedListItem) {
      setSelectedListItem(initSelectedListItem)
    }
  }, [initSelectedListItem])

  // Kill a mosquito with a bazooka *****========>>>>>> . BOOM!!!
  useEffect(() => {
    const selectedChildren = ref.current?.querySelectorAll('.selected-child')
    if (selectedChildren && selectedChildren.length > 0) {
      selectedChildren[selectedChildren.length - 1].classList.add('last')
    }
  }, [selectedListItem])

  const onItemSelect = useCallback(
    (item?: ListItem<T | K>) => {
      setSelectedListItem(item?.uuid)
      if (onSelect) {
        onSelect(item)
      }
    },
    [onSelect],
  )

  const onToggle = useCallback((item: ListItem<T | K>) => {
    setCollapsedIds((prevData) => {
      if (prevData) {
        const newData = [...prevData]
        const itemIndex = newData.indexOf(item.uuid)
        if (itemIndex > -1) {
          newData.splice(itemIndex, 1)
        } else {
          newData.push(item.uuid)
        }
        return newData
      }
      return prevData
    })
  }, [])

  return (
    <ListStyled ref={ref} onDragStart={onDragStartList} onDrop={onDropList}>
      {items &&
        items.map((item) => (
          <RenderItemMemo
            key={item.uuid}
            {...{
              collapsedIds,
              item: item as ListItem<T>,
              onSelect: onItemSelect,
              onToggle,
              selectedListItem,
              ...other,
            }}
          />
        ))}
    </ListStyled>
  )
}

export default List
