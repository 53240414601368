/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { forwardRef } from 'react'
import {
  flexbox,
  space,
  layout,
  typography,
  variant as variantStyled,
} from 'styled-system'
import css from '@styled-system/css'
import styled from 'themes'

import {
  TypographyComponentProps,
  TypographyRefProps,
} from './typography.types'

const SpanStyled = styled.span<TypographyComponentProps>(
  flexbox,
  layout,
  space,
  typography,
  ({ color }) =>
    css({
      outline: '0 none',
      color: color === 'inherit' ? 'inherit' : `content.${color}`,
    }),
  ({ theme: { typography: typographyTheme } }) =>
    variantStyled({
      variants: { ...typographyTheme },
    }),
  ({ fontFamily }) =>
    css({
      fontFamily,
    }),
  () =>
    variantStyled({
      prop: 'ellipsis',
      variants: {
        true: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        },
      },
    }),
)

export const Typography = forwardRef<
  TypographyRefProps,
  TypographyComponentProps
>(
  (
    {
      children,
      color = 'primary',
      ellipsis = false,
      fontFamily,
      variant = 'body1',
      display = 'block',
      ...other
    },
    forwardedRef,
  ) => (
    <SpanStyled
      ref={forwardedRef}
      variant={
        fontFamily === 'JetBrains Mono' && variant === 'caption'
          ? 'captionMono'
          : variant
      }
      {...{ color, display, ellipsis, fontFamily, ...other }}
    >
      {children}
    </SpanStyled>
  ),
)

export default Typography
