/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Box } from 'reflexbox'
import styled from 'themes'
import { space, SpaceProps } from 'styled-system'
import css from '@styled-system/css'

export type DialogActionsProps = SpaceProps

const DialogActionsStyled = styled(Box)(
  space,
  css({
    '> *': {
      marginRight: 2,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  }),
)

const DialogActions: React.FC<DialogActionsProps> = ({
  children,
  ...other
}) => (
  <DialogActionsStyled
    display="flex"
    justifyContent="flex-end"
    mt={4}
    {...other}
  >
    {children}
  </DialogActionsStyled>
)

export default DialogActions
