const darkGray = {
  10: '#1D1D20',
  50: '#27272B',
  100: '#303136',
  200: '#3A3B40',
  300: '#4B4C53',
  400: '#55565E',
  500: '#6D6F78',
}

export default darkGray
