/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import styled from 'themes'
import css from '@styled-system/css'
import { Icon } from 'components/data-display/icon'
import { SwitchBase, SwitchBaseProps } from 'components/inputs/switch-base'

const SwitchBaseStyled = styled(SwitchBase)<SwitchBaseProps>(
  ({ checked, disabled }) =>
    css({
      '.switch-base-icon': {
        borderRadius: 'default',
        bg: () => {
          if (disabled) {
            return 'fills.quanternary'
          }
          return checked ? 'backgrounds.accent' : 'backgrounds.primary'
        },
      },
    }),
)

const Checkbox: React.FC<SwitchBaseProps> = (props) => (
  <SwitchBaseStyled
    type="checkbox"
    icon={
      <Icon
        symbol="check"
        color="inherit"
        // eslint-disable-next-line react/destructuring-assignment
        size={props.size === 'large' ? 16 : 14}
      />
    }
    {...props}
  />
)

export default Checkbox
