/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import {
  KeyboardEvent,
  forwardRef,
  MutableRefObject,
  FormEvent,
  useState,
  useEffect,
  useCallback,
  createRef,
} from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import css from '@styled-system/css'
import setCaret from 'utils/set-caret'
import {
  Typography,
  TypographyComponentProps,
  TypographyRefProps,
} from 'components/data-display'
import { InputBaseComponentProps } from './input-base.types'

const TypographyComponent = forwardRef<
  TypographyRefProps,
  Omit<TypographyComponentProps, 'onBlur' | 'onClick' | 'onChange'>
>((props, forwardedRef) => <Typography ref={forwardedRef} {...props} />)

const InputComponentStyled = styled(
  TypographyComponent,
)<InputBaseComponentProps>(
  {
    outline: '0 none',
    backgroundClip: 'padding-box',
    border: 'none',
    resize: 'none',
    overflow: 'hidden',
    display: 'block',
    position: 'relative',
    zIndex: 10,
    userSelect: 'none',
  },
  ({
    width = '100%',
    editable,
    height,
    minHeight,
    multiline,
    password,
    value,
    embedded,
    placeholder = '',
  }) =>
    css({
      display: !multiline ? 'block' : undefined,
      whiteSpace: !multiline ? 'nowrap' : undefined,
      alignItems: 'center',
      width,
      height,
      minHeight,
      cursor: embedded && !editable ? 'inherit' : 'text',
      // \u200b – a hack to keep a proper height for empty
      '&:empty:before': {
        content: `"${
          value === undefined || (value?.trim().length === 0 && placeholder)
            ? placeholder
            : '\u200b'
        }"`,
        color: 'content.tertiary',
        position: 'absolute',
        left: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
      },
      '&[disabled], &:disabled': {
        cursor: 'default',
        color: 'content.tertiary',
        '&::placeholder': {
          color: 'content.tertiary',
        },
      },
      ...(password
        ? {
            '&:not(:empty)': {
              '-webkit-text-security': 'disc',
            },
          }
        : undefined),
    }),
)

export const InputBase = forwardRef<
  TypographyRefProps,
  InputBaseComponentProps
>(
  (
    {
      color = 'primary',
      disabled = false,
      readOnly = false,
      focusMode = 'default',
      editable = true,
      multiline = false,
      embedded = false,
      ellipsis = false,
      password = false,
      onPressEnter,
      onPressEscape,
      value,
      caretPosition,
      onChange,
      onBlur: onBlurProp,
      ...other
    },
    forwardedRef,
  ) => {
    const ref = forwardedRef
      ? (forwardedRef as MutableRefObject<TypographyRefProps | null>)
      : createRef<TypographyRefProps>()
    const [setupFocus, setSetupFocus] = useState(false)
    const [refCurrent, setRefCurrent] = useState<HTMLSpanElement | null>(null)
    const [inputValue, setInputValue] = useState<string | undefined>(value)

    useEffect(() => {
      if (refCurrent?.textContent !== value) {
        setInputValue(value)
      }
    }, [refCurrent, value])

    useEffect(() => {
      setRefCurrent(ref?.current)
    }, [ref])

    useEffect(() => {
      if (!refCurrent || refCurrent.textContent === value) {
        return
      }

      refCurrent.textContent = value ?? ''

      // A hack to fix a placeholder that moves to the left in case
      // when the text is longer than input viewport
      if (
        refCurrent.textContent.trim() === '' &&
        typeof refCurrent.scrollTo === 'function'
      ) {
        refCurrent.scrollTo({ left: 0, top: 0 })
      }
    }, [refCurrent, value])

    useEffect(() => {
      if (refCurrent && refCurrent.classList) {
        refCurrent.classList.add('mousetrap')
      }

      if (editable && caretPosition !== undefined && !setupFocus) {
        setTimeout(() => {
          const element = (refCurrent?.firstChild ?? refCurrent) as HTMLElement
          if (focusMode === 'default') {
            setCaret(
              element,
              value && value.trim().length > 0 && caretPosition !== undefined
                ? caretPosition
                : 0,
            )
          }
          if (focusMode === 'select-all') {
            const range = document.createRange()
            range.selectNodeContents(element)
            const selelection = window.getSelection()
            if (selelection) {
              selelection.removeAllRanges()
              selelection.addRange(range)
            }
          }
          setSetupFocus(true)
        }, 10)
      }
    }, [editable, focusMode, refCurrent, caretPosition, value, setupFocus])

    const onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        const target = refCurrent
        if (event.key === 'Enter' && !multiline && onPressEnter && target) {
          event.preventDefault()
          target.blur()
          onPressEnter(target.textContent ?? '')
        }
        if (event.key === 'Escape' && onPressEscape) {
          onPressEscape()
        }
      },
      [multiline, onPressEnter, onPressEscape, refCurrent],
    )

    const onInput = useCallback(
      (event: FormEvent<TypographyRefProps>) => {
        if (onChange) {
          onChange(event.currentTarget.textContent ?? '')
        }
      },
      [onChange],
    )

    const onBlur = useCallback(() => {
      setSetupFocus(false)

      if (refCurrent && typeof refCurrent.scrollTo === 'function') {
        refCurrent.scrollTo({ left: 0, top: 0 })
      }

      if (onBlurProp) {
        onBlurProp(refCurrent?.textContent ?? undefined)
      }
    }, [onBlurProp, refCurrent])

    return (
      <InputComponentStyled
        contentEditable={!disabled && !readOnly && (editable || multiline)}
        spellCheck={false}
        tabIndex={disabled ? -1 : 0}
        height="100%"
        overflow={multiline ? 'auto' : 'initial'}
        ellipsis={!multiline && ellipsis}
        suppressContentEditableWarning
        {...{
          color,
          disabled,
          editable,
          embedded,
          multiline,
          onBlur,
          onInput,
          onKeyDown,
          password,
          ref,
        }}
        {...other}
      >
        {inputValue}
      </InputComponentStyled>
    )
  },
)

export default InputBase
