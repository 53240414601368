/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Box } from 'reflexbox'
import {
  color as colorStyled,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'
import css from '@styled-system/css'
import styled, { global } from 'themes'
import { IconSymbol } from 'themes/common.types'

export const iconSymbols = [
  'API',
  'add',
  'add-user',
  'adjustment',
  'alert',
  'analytics',
  'array',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'assert-different',
  'attach',
  'bold',
  'boolean',
  'brackets',
  'branch',
  'calendar',
  'card',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'caret-up-down',
  'certificate',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clock',
  'close',
  'cloud',
  'code',
  'comment',
  'commit',
  'compare',
  'copy',
  'danger',
  'dark-theme',
  'dashboard',
  'database',
  'decode',
  'different',
  'document',
  'drag',
  'duplicate',
  'edit',
  'encode',
  'envelope',
  'environment',
  'equal',
  'exist',
  'extension',
  'eye',
  'eye-false',
  'filter',
  'folder',
  'fork',
  'funnel',
  'get',
  'greater',
  'help',
  'history',
  'home',
  'info',
  'italic',
  'key',
  'less',
  'light-theme',
  'link',
  'list',
  'location',
  'lock',
  'luckymarmot',
  'map',
  'marketplace',
  'menu',
  'merge',
  'more-vertical',
  'network',
  'notification',
  'null',
  'num',
  'object',
  'order',
  'paw',
  'performance',
  'picture',
  'play',
  'pull-request',
  'put',
  'quote',
  'redo',
  'refresh',
  'restart',
  'search',
  'secure',
  'send',
  'settings',
  'share',
  'sidebar-hide',
  'sidebar-show',
  'sort',
  'spinner',
  'star',
  'strikethrough',
  'string',
  'subtract',
  'team',
  'terminal',
  'test',
  'theme-dark',
  'theme-light',
  'thumb-down',
  'thumb-up',
  'transaction',
  'trash',
  'twitter',
  'undo',
  'unlock',
  'unsecure',
  'updates',
  'user',
  'variable',
  'workspace',
]

type IconProps = ColorProps &
  SpaceProps &
  LayoutProps & {
    iconPath?: string
    symbol?: IconSymbol
    color?: string
    size?: number
  }

const Svg = styled.svg(
  layout,
  colorStyled,
  space,
  ({ color = 'content.primary' }) =>
    css({
      display: 'inline-block',
      verticalAlign: 'middle',
      color,
    }),
)

const Icon: React.FC<IconProps> = ({
  display = 'inline-flex',
  symbol = 'paw',
  color = undefined,
  size = 16,
  minWidth = 'auto',
  iconPath = global.iconPath,
  ...other
}) => (
  <Box minWidth={minWidth} display={display} {...other}>
    <Svg width={size} height={size} color={color} symbol={symbol}>
      <use xlinkHref={`${iconPath}#${symbol}`} />
    </Svg>
  </Box>
)

export default Icon
