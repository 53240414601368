/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import React, {
  ClipboardEvent,
  KeyboardEvent,
  MouseEvent,
  memo,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react'
import { jsx } from '@emotion/core'
import styled from 'themes'
import { Box, Flex } from 'reflexbox'
import { getUuid, classNames, setCaret } from 'utils'
import { space as spaceStyled } from 'styled-system'
import css from '@styled-system/css'
import { Menu } from 'components/navigation/menu'
import { MenuItem } from 'components/navigation/menu/menu-item'
import { Skeleton } from 'components/feedback/skeleton'

import DynamicFieldContainerStyled from './dynamic-field-container'
import {
  DynamicFieldVariable,
  dynamicFieldVariableStyle,
} from './dynamic-field-variable'
import {
  EMPTY_SPACE,
  getCaretRelativePosition,
  RichTextEditorClass,
  cleanEditorValue,
  CaretRelativePosition,
} from './helpers'
import {
  DynamicFieldInputSizes,
  DynamicFieldComponentProps,
  DynamicFieldItem,
  DynamicFieldSuggestion,
} from './dynamic-field.types'

const SuggestionMenuItemStyled = styled(MenuItem)(() =>
  css({
    '&:hover, &:focus': {
      '& span': {
        color: 'common.white',
      },
    },
  }),
)

const DynamicFieldStyled = styled(Box)<{
  readOnly?: boolean
  variant?: DynamicFieldInputSizes
  representation: 'default' | 'hover'
  multiline?: boolean
  placeholderText?: string
}>(
  {
    spaceStyled,
  },
  ({ readOnly, variant, multiline, theme, placeholderText }) =>
    css({
      ...theme.typography[variant === 'large' ? 'body1' : 'body2'],
      outline: '0 none',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      alignContent: 'flex-start',
      color: 'content.primary',
      minWidth: 'fit-content',
      flexWrap: multiline ? 'wrap' : 'nowrap',
      minHeight: variant === 'large' ? 24 : 20,
      '&:before': {
        height: variant === 'large' ? 24 : 20,
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        content: placeholderText ? `"${placeholderText}"` : undefined,
        color: 'content.tertiary',
        position: 'absolute',
        zIndex: -1,
      },
      '&:focus': {
        '&:before': {
          display: 'none',
        },
      },
      p: {
        minWidth: '1ch',
        minHeight: variant === 'large' ? 24 : 20,
        lineHeight: () => {
          const extra = multiline ? 2 : 0
          return `${variant === 'large' ? 24 + extra : 20 + extra}px`
        },
        whiteSpace: multiline ? 'normal' : 'nowrap',
        alignItems: 'center',
        marginTop: '2px',
        marginBottom: 0,
        width: '100%',
        '&:first-of-type': {
          marginTop: 0,
        },
      },
      span: {
        ...theme.typography[variant === 'large' ? 'body1' : 'body2'],
        height: variant === 'large' ? 24 : 20,
        position: 'relative',
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        alignItems: 'center',
        minWidth: '2px',
        margin: '0 1px',
        '&.dynamic-value': {
          ...dynamicFieldVariableStyle({ theme, variant })(),
          cursor: readOnly ? 'default' : 'pointer',
        },
        '&.dynamic-value-anchor': {
          ...dynamicFieldVariableStyle({ theme, variant, isAnchor: true })(),
        },
      },
    }),
)

const DynamicField: React.FC<DynamicFieldComponentProps> = ({
  readOnly = false,
  dynamicValues = {},
  suggestions = [],
  variant = 'default',
  hover = false,
  focused = false,
  multiline = false,
  fullWidth = false,
  placeholder,
  value = '',
  onChange,
  onDynamicValueClick,
  width = '24ch',
  height,
}) => {
  const [objectValue, setObjectValue] = useState<
    DynamicFieldItem[][] | undefined
  >()
  const [insertingRange, setInsertingRange] = useState<Range | undefined>()
  const [caretRelativePosition, setCaretRelativePosition] = useState<
    CaretRelativePosition | undefined
  >()
  const [placeholderText, setPlaceholderText] = useState<string | undefined>()
  const [initialContent, setInitialContent] = useState<
    DocumentFragment | undefined
  >()
  const [insertingSuggestion, setInsertingSuggestion] = useState<
    DynamicFieldSuggestion | undefined
  >()

  const menuRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)

  const richTextEditor = useMemo(() => new RichTextEditorClass(ref), [ref])
  const anchorEl = useMemo(
    () => insertingRange?.startContainer?.parentElement,
    [insertingRange],
  )
  const isEditing = useMemo(
    () => !hover || Boolean(anchorEl),
    [hover, anchorEl],
  )

  // Put an empty space if empty paragraph
  const paragraphEmptySpace = useCallback(
    (paragraphElement: HTMLParagraphElement) => {
      if (paragraphElement.textContent?.length === 0) {
        paragraphElement.appendChild(
          richTextEditor.createNode({ content: EMPTY_SPACE }),
        )
      }
    },
    [richTextEditor],
  )

  const parseToObject = useCallback(
    (sourceString?: string) => {
      // Return default value
      if (!sourceString) {
        return [[{ key: getUuid(), text: EMPTY_SPACE }]]
      }

      const parsedResult: DynamicFieldItem[][] = []
      const existsKeys = Object.keys(dynamicValues).reduce((keys, key) => {
        if (sourceString && sourceString.includes(key)) {
          keys.push(key)
        }
        return keys
      }, [] as string[])

      const parsedLines = sourceString?.split(/\r?\n/)

      if (parsedLines) {
        parsedLines.forEach((lineValue) => {
          const parsedLineValues: DynamicFieldItem[] = []
          if (existsKeys.some((key) => lineValue.includes(key))) {
            lineValue
              .split(new RegExp(`(${existsKeys.join('|')})`, 'g'))
              .forEach((val: string) => {
                const item =
                  val in dynamicValues
                    ? { key: getUuid(), id: val, text: dynamicValues[val] }
                    : {
                        key: getUuid(),
                        text: val && val.trim().length > 0 ? val : EMPTY_SPACE,
                      }
                parsedLineValues.push(item)
              })
          } else {
            parsedLineValues.push({
              key: getUuid(),
              text:
                lineValue && lineValue.trim().length > 0
                  ? lineValue
                  : EMPTY_SPACE,
            })
          }

          parsedResult.push(
            parsedLineValues.length > 0
              ? parsedLineValues
              : [{ key: getUuid(), text: EMPTY_SPACE }],
          )
        })
      }

      return parsedResult
    },
    [dynamicValues],
  )

  useEffect(() => {
    if (!insertingSuggestion) {
      return
    }

    const selection = richTextEditor.getSelection()
    if (insertingRange && selection) {
      const clone = insertingRange.cloneRange()
      clone.collapse()
      selection.removeAllRanges()
      selection.addRange(clone)
    }

    setInsertingSuggestion(undefined)
    setInsertingRange(undefined)
  }, [insertingSuggestion, insertingRange, value, richTextEditor])

  useEffect(() => {
    setInitialContent((prevInitialContent) => {
      if (!objectValue || prevInitialContent) {
        return prevInitialContent
      }

      const contentResult = document.createDocumentFragment()
      const singleParagraphElement = document.createElement('p')

      objectValue.forEach((line: DynamicFieldItem[]) => {
        const paragraphElement = document.createElement('p')

        line.forEach(({ key, id, text }: DynamicFieldItem) => {
          const paragraphContainer = multiline
            ? paragraphElement
            : singleParagraphElement

          if (id !== undefined) {
            paragraphContainer.appendChild(
              richTextEditor.createNode({
                id,
                contentEditable: false,
                content: text,
                className: 'dynamic-value',
              }),
            )
          } else {
            paragraphContainer.appendChild(
              richTextEditor.createNode({
                key,
                content: cleanEditorValue(text) !== '' ? text : EMPTY_SPACE,
              }),
            )
          }
        })

        paragraphEmptySpace(paragraphElement)

        if (multiline) {
          contentResult.appendChild(paragraphElement)
        }
      })
      if (!multiline) {
        paragraphEmptySpace(singleParagraphElement)
        contentResult.appendChild(singleParagraphElement)
      }

      return contentResult
    })
  }, [richTextEditor, objectValue, multiline, paragraphEmptySpace])

  useEffect(() => {
    // Need a timeout due to the delay with update of Editor's DOM
    setTimeout(() => {
      setPlaceholderText(
        richTextEditor.isEmpty() && placeholder ? placeholder : undefined,
      )
    }, 1)
  }, [richTextEditor, placeholder, initialContent, value])

  useEffect(() => {
    setInitialContent(undefined)
    richTextEditor.updateDynamicValues(dynamicValues)
  }, [dynamicValues, richTextEditor])

  useEffect(() => {
    if (typeof value !== 'string') {
      throw new Error('DynamicField value is not a string')
    }

    setObjectValue(parseToObject(value))

    const editor = richTextEditor.getEditor()

    if (!editor) {
      setObjectValue(parseToObject(value))
      return
    }

    setObjectValue(parseToObject(value))
    setInitialContent(undefined)
  }, [value, parseToObject, richTextEditor])

  useEffect(() => {
    const editor = richTextEditor.getEditor()
    if (!editor) {
      return
    }

    if (editor.classList) {
      editor.classList.add('mousetrap')
    }

    if (
      initialContent &&
      (richTextEditor.parseToString() !== value ||
        editor.querySelectorAll('p').length === 0)
    ) {
      const editorNode = editor as Node
      // @TODO: remove @ts-ignore once replaceChildren will be introduced in TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof editorNode.replaceChildren === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        editorNode.replaceChildren(initialContent)
      }
    }
  }, [richTextEditor, initialContent, value])

  const onCopy = useCallback(
    (event: ClipboardEvent<HTMLDivElement>, cut?: boolean) => {
      if (!cut && navigator.clipboard) {
        event.preventDefault()
      }

      richTextEditor.copySelectedToClipboard()

      if (cut) {
        setTimeout(() => {
          richTextEditor.cleanUpHTML()
          richTextEditor.getEditor()?.blur()
        }, 0)
      }
    },
    [richTextEditor],
  )

  const onPaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault()
      const editor = richTextEditor.getEditor()
      if (!editor) {
        throw new Error('DynamicField editor is undefined')
      }

      richTextEditor.pasteToEditor(event.clipboardData.getData('text/plain'))
      setInitialContent(undefined)
      setObjectValue(parseToObject(richTextEditor.parseToString()))

      editor.blur()
    },
    [richTextEditor, parseToObject],
  )

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const activeElement = document.activeElement as HTMLDivElement
      if (!activeElement) {
        return
      }

      if (event.key === 'ArrowDown' && insertingRange) {
        event.preventDefault()
        const firstChild = menuRef?.current?.firstChild as HTMLElement
        if (firstChild) {
          firstChild.focus()
        }
        return
      }

      if (event.key === 'ArrowRight') {
        const node = richTextEditor.isNextEmptySpace()
        const nextNode = node?.nextSibling?.nextSibling
        if (nextNode) {
          setCaret(nextNode, 0)
        } else if (node) {
          setCaret(node, 0)
        }
        setInsertingRange(undefined)
      }

      if (event.key === 'ArrowLeft') {
        const node = richTextEditor.isPrevEmptySpace()
        const prevNode = node?.previousSibling?.previousSibling
        if (prevNode) {
          setCaret(prevNode, prevNode.textContent?.length ?? 1)
        }
        setInsertingRange(undefined)
      }

      if (event.key === 'Enter') {
        event.preventDefault()
        if (menuRef.current && insertingRange) {
          if (menuRef?.current?.firstChild instanceof HTMLElement) {
            menuRef?.current?.firstChild?.click()
          }
          return
        }
        if (!multiline) {
          return
        }

        richTextEditor.splitParagraphs()
        return
      }

      // Remove manually the whole anchor element if it's fired by pressing Backspace at the beginning
      if (event.key === 'Backspace') {
        const node = richTextEditor.isPrevEmptySpace()
        if (!node?.parentNode) {
          return
        }

        node.parentNode.removeChild(node)
        if (node.previousSibling) {
          node.parentNode.removeChild(node.previousSibling)
        }
      }

      // Remove manually the whole anchor element if it's fired by pressing Delete at the end
      if (event.key === 'Delete') {
        const node = richTextEditor.isNextEmptySpace()
        if (!node?.parentNode) {
          return
        }

        node.parentNode.removeChild(node)
        if (node.nextSibling) {
          node.parentNode.removeChild(node.nextSibling)
        }
      }
    },
    [insertingRange, multiline, richTextEditor],
  )

  const onKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const activeElement = document.activeElement as HTMLDivElement
      if (!activeElement) {
        return
      }
      if (event.key === '{') {
        const { focusNode } = richTextEditor.getSelection() ?? {}
        const range = richTextEditor.getCaretRange()
        if (!focusNode || !range) {
          return
        }

        setCaretRelativePosition(getCaretRelativePosition(range))

        return
      }

      // Call cleanUpHTML if editor content is not within a paragraph
      if (activeElement.firstChild?.nodeName !== 'P') {
        richTextEditor.cleanUpHTML()
      }

      if (event.key === 'Backspace' || event.key === 'Delete') {
        richTextEditor.cleanUpHTML()
      }

      if (event.key === 'Enter') {
        event.preventDefault()
        if (multiline) {
          richTextEditor.cleanUpHTML()
        }
      }
    },
    [multiline, richTextEditor],
  )

  const updateValue = useCallback(
    (
      val: string | undefined,
      insertedSuggestion?: DynamicFieldSuggestion | undefined,
    ) => {
      if (onChange) {
        onChange(val, insertedSuggestion)
      }
    },
    [onChange],
  )

  const onInsertSuggestion = useCallback(
    (id: string) => {
      if (!insertingRange) {
        return
      }
      const suggestion = suggestions.find(({ id: anId }) => anId === id)
      setInsertingSuggestion(suggestion)

      if (suggestion) {
        insertingRange.extractContents()

        const range = document.createRange()

        if (suggestion.type === 'text') {
          const suggestionNode = document.createTextNode(suggestion.id)
          insertingRange.insertNode(suggestionNode)

          updateValue(richTextEditor.parseToString())

          range.setStart(
            suggestionNode,
            suggestionNode.textContent?.length ?? 0,
          )
        } else {
          suggestion.newId = getUuid()
          const suggestionNode = richTextEditor.createNode({
            id: `#${suggestion.newId}#`,
            contentEditable: false,
            content: suggestion.title,
            className: 'dynamic-value',
          })

          const emptySpaceNode = document.createTextNode(EMPTY_SPACE)
          insertingRange.insertNode(emptySpaceNode)
          insertingRange.insertNode(suggestionNode)

          updateValue(richTextEditor.parseToString(), suggestion)

          range.setStart(emptySpaceNode, 0)
        }

        setInsertingRange(range)
      }
    },
    [richTextEditor, updateValue, suggestions, insertingRange],
  )

  const onInput = useCallback(() => {
    const { focusNode } = richTextEditor.getSelection() ?? {}
    const range = richTextEditor.getCaretRange()

    if (!focusNode || !range) {
      return
    }

    const rangeClone = range.cloneRange()
    rangeClone.setStart(focusNode, 0)

    const lastAnchorIndex = rangeClone.toString().lastIndexOf('{')
    if (lastAnchorIndex > -1) {
      rangeClone.setStart(focusNode, lastAnchorIndex)
    }

    setCaretRelativePosition(getCaretRelativePosition(rangeClone))
    setInsertingRange(rangeClone)
  }, [richTextEditor])

  // Filter suggestions for the Dynamic Values popup
  const filteredSuggestions = useMemo(() => {
    const string = insertingRange?.toString()
    const isDynamicValue = string?.includes('{')
    const filterValue = cleanEditorValue(string?.replace('{', ''))

    return suggestions
      .filter(
        ({ type }) =>
          (isDynamicValue && type !== 'text') ||
          (!isDynamicValue && type === 'text'),
      )
      .filter(
        ({ title }) =>
          !filterValue ||
          filterValue.localeCompare(
            title.substr(0, Math.min(title.length, filterValue.length)),
            'en',
            {
              sensitivity: 'base',
              ignorePunctuation: true,
            },
          ) === 0,
      )
      .sort((a, b) =>
        a.title.localeCompare(b.title, 'en', {
          sensitivity: 'base',
          ignorePunctuation: true,
        }),
      )
      .slice(0, 20)
  }, [suggestions, insertingRange])

  const onBlur = useCallback(() => {
    const string = richTextEditor.parseToString()
    setPlaceholderText(
      richTextEditor.isEmpty() && placeholder ? placeholder : undefined,
    )
    if (value !== string && !menuRef.current) {
      updateValue(string)
      setInsertingRange(undefined)
    }
  }, [updateValue, value, menuRef, richTextEditor, placeholder])

  const onClick = useCallback(
    (event: MouseEvent) => {
      const element = event?.target as HTMLDivElement
      const id = element.getAttribute('data-id')
      if (!readOnly && element && id && onDynamicValueClick) {
        onDynamicValueClick(element, id)
      }
    },
    [onDynamicValueClick, readOnly],
  )

  const onContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      const { focusNode } = richTextEditor.getSelection() ?? {}
      const range = richTextEditor.getCaretRange()
      const editor = richTextEditor.getEditor()

      if (
        !focusNode ||
        !range ||
        focusNode?.parentElement?.tagName === 'SPAN' ||
        !editor?.contains(focusNode ?? null)
      ) {
        return
      }

      const anchorNode = document.createTextNode('{')
      range.insertNode(anchorNode)

      setCaretRelativePosition(getCaretRelativePosition(range))
      setInsertingRange(range)
      setCaret(anchorNode, 1)
    },
    [richTextEditor],
  )

  const representation = hover ? 'hover' : 'default'

  if (!initialContent) {
    return (
      <Skeleton variant="rect" height={32} width={fullWidth ? '100%' : width} />
    )
  }

  return (
    <React.Fragment>
      <DynamicFieldContainerStyled
        className={classNames({
          focused: focused || (hover && Boolean(anchorEl)),
        })}
        tabIndex={isEditing ? -1 : undefined}
        {...{
          multiline,
          variant,
          width,
          fullWidth,
          onContextMenu,
          onBlur,
          representation,
        }}
      >
        <Flex
          overflow="hidden"
          alignSelf={!multiline ? 'center' : undefined}
          height={height}
        >
          <DynamicFieldStyled
            tabIndex={0}
            contentEditable={!readOnly}
            spellCheck={false}
            onCut={(event) => onCopy(event, true)}
            {...{
              ref,
              readOnly,
              variant,
              onCopy,
              onPaste,
              onKeyDown,
              onKeyUp,
              onInput,
              onClick,
              onContextMenu,
              multiline,
              placeholderText,
              representation,
            }}
          />
        </Flex>
      </DynamicFieldContainerStyled>
      {insertingRange && filteredSuggestions && filteredSuggestions.length > 0 && (
        <Menu
          ref={menuRef}
          popoverProps={{
            anchorEl,
            open:
              insertingRange
                ?.toString()
                .replaceAll(new RegExp(EMPTY_SPACE, 'g'), '').length > 0,
            onClose: () => setInsertingRange(undefined),
            marginLeft: caretRelativePosition?.left,
            marginTop: caretRelativePosition?.bottom,
          }}
        >
          {filteredSuggestions.map(({ id, title, type }) => (
            <SuggestionMenuItemStyled
              key={id}
              onSelect={() => onInsertSuggestion(id)}
            >
              {type === 'text' ? (
                title
              ) : (
                <DynamicFieldVariable>{title}</DynamicFieldVariable>
              )}
            </SuggestionMenuItemStyled>
          ))}
        </Menu>
      )}
    </React.Fragment>
  )
}

export default memo(
  DynamicField,
  (prevProps, nextProps) =>
    Object.keys(prevProps).find(
      (key) =>
        key !== 'dynamicValues' &&
        prevProps[key as keyof typeof prevProps] !==
          nextProps[key as keyof typeof nextProps],
    ) === undefined &&
    JSON.stringify(prevProps.dynamicValues) ===
      JSON.stringify(nextProps.dynamicValues),
) as typeof DynamicField
