import addAlpha from 'utils/add-alpha'
import * as colors from '../colors'
import global from '../global'
import backgrounds from './backgrounds'
import borders from './borders'
import content from './content'
import fills from './fills'

const ligthTheme = {
  ...global,
  isDark: false,
  shadows: {
    small: `0px 1px 2px ${addAlpha(
      backgrounds.scrim,
      0.1,
    )}, 0px 2px 8px ${addAlpha(backgrounds.scrim, 0.08)}`,
    medium: `0px 1px 1px ${addAlpha(
      backgrounds.scrim,
      0,
    )}, 0px 2px 4px ${addAlpha(
      backgrounds.scrim,
      0.1,
    )}, 0px 4px 12px ${addAlpha(backgrounds.scrim, 0.12)}`,
    large: `0px 1px 2px ${addAlpha(
      backgrounds.scrim,
      0,
    )}, 0px 4px 12px ${addAlpha(
      backgrounds.scrim,
      0.1,
    )}, 0px 12px 36px ${addAlpha(backgrounds.scrim, 0.12)}`,
  },
  borders: {
    thin: '1px solid #ddd', // deprecated
    disclosure: '2px solid #eee', // deprecated
    dropzoneDefault: `2px dashed ${borders.default}`,
    dropzoneDragOver: `2px dashed ${borders.accent}`,
  },
  colors: {
    ...colors,
    backgrounds,
    borders,
    content,
    fills,
  },
  borderWidth: {
    default: '1px',
  },
}

export default ligthTheme
