/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { memo } from 'react'
import { Box } from 'reflexbox'
import { jsx } from '@emotion/core'
import {
  space as spaceStyled,
  typography,
  variant as variantStyled,
} from 'styled-system'
import css from '@styled-system/css'
import styled from '../../../themes'
import { classNames } from '../../../utils'
import {
  InputContainerInputSizes,
  InputContainerComponentProps,
} from './input-container.types'

const getBorder = ({
  variant,
  representation,
}: {
  variant?: InputContainerInputSizes
  representation?: 'default' | 'hover'
}) => {
  if (representation === 'default') {
    return { top: '-2px', bottom: '-2px', left: '-2px', right: '-2px' }
  }
  return variant === 'large'
    ? { top: '-10px', bottom: '-10px', left: '-14px', right: '-14px' }
    : { top: '-8px', bottom: '-8px', left: '-10px', right: '-10px' }
}

const InputContainerStyled = styled(Box)<InputContainerComponentProps>(
  spaceStyled,
  typography,
  {
    outline: '0 none',
    backgroundClip: 'padding-box',
    border: 'none',
    position: 'relative',
    alignItems: 'flex-start',
  },
  ({ variant, embedded, theme, representation, maxWidth }) =>
    css({
      ...theme.typography[variant === 'large' ? 'body1' : 'body2'],
      maxWidth,
      cursor: representation === 'default' ? 'text' : 'default',
      borderRadius: 'default',
      padding: () => {
        if (embedded || representation === 'hover') {
          return undefined
        }
        return variant === 'large' ? '8px 12px' : '6px 8px'
      },
      '&::before': {
        position: 'absolute',
        content: '""',
        boxSizing: 'content-box',
        margin: 'auto',
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 'default',
        borderColor: 'borders.accent',
        zIndex: 1,
        opacity: 0,
        transition: 'opacity .2s ease, border-color .2s ease',
        ...getBorder({ representation, variant }),
      },
    }),
  ({ disabled, embedded, editable }) =>
    variantStyled({
      prop: 'representation',
      variants: {
        default: {
          borderColor: 'fills.quanternary',
          backgroundColor: 'fills.quanternary',
          '&.error': {
            '&::before': {
              opacity: 1,
              borderWidth: 1,
              borderColor: 'borders.error',
              top: '-1px',
              bottom: '-1px',
              left: '-1px',
              right: '-1px',
            },
          },
          '&:focus-within, &.focused': {
            '&::before': {
              opacity: disabled ? 0 : 1,
            },
          },
        },
        hover: {
          position: 'relative',
          borderColor: 'transparent',
          '&::before': editable
            ? {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              }
            : undefined,
          '&:focus-within, &.focused': editable
            ? {
                '&::before': {
                  opacity: embedded || disabled ? 0 : 1,
                  borderColor: embedded ? undefined : 'borders.accent',
                },
                '&.error': {
                  '&::before': {
                    borderColor: 'borders.error',
                    borderWidth: 1,
                  },
                },
              }
            : undefined,
        },
      },
    }),
)

export const InputContainer: React.FC<InputContainerComponentProps> = ({
  children,
  className = '',
  disabled = false,
  endAdornment,
  error,
  focused,
  maxWidth,
  representation = 'default',
  startAdornment,
  variant = 'default',
  width,
  ...other
}) => (
  <InputContainerStyled
    className={`${classNames({ focused, error }) ?? ''} ${className}`}
    display="flex"
    alignItems="center"
    fontFamily="default"
    maxWidth={maxWidth || 'inherit'}
    tabIndex={disabled ? -1 : 0}
    {...{ disabled, variant, representation, width, ...other }}
  >
    {startAdornment}
    {children}
    {endAdornment}
  </InputContainerStyled>
)

export default memo(InputContainer)
