/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { forwardRef, ReactNode } from 'react'
import { jsx } from '@emotion/core'
import css from '@styled-system/css'
import {
  flexbox,
  layout,
  shadow,
  FlexboxProps,
  LayoutProps,
  ShadowProps,
  space,
  SpaceProps,
} from 'styled-system'
import { Box } from 'reflexbox'
import styled from 'themes'

export type ElevateProps = FlexboxProps &
  LayoutProps &
  ShadowProps &
  SpaceProps & {
    variant?: 'small' | 'medium' | 'large'
    rounded?: boolean
    children?: ReactNode | ReactNode[] | string
  }

const ElevateStyled = styled(Box)(
  flexbox,
  layout,
  shadow,
  space,
  ({ variant, rounded }) =>
    css({
      borderRadius: rounded ? 'default' : 0,
      boxShadow: variant,
      backgroundColor: 'backgrounds.primary',
    }),
)

const Elevation = forwardRef<HTMLDivElement, ElevateProps>(
  ({ variant = 'small', rounded = true, children, ...other }, forwardedRef) => (
    <ElevateStyled
      ref={forwardedRef}
      className="elevation"
      p={3}
      m={2}
      {...{ variant, rounded }}
      {...other}
    >
      {children}
    </ElevateStyled>
  ),
)

export default Elevation
