import addAlpha from 'utils/add-alpha'
import { darkGray, blue, red, green } from '../colors'

const borders = {
  default: addAlpha(darkGray[10], 0.08),
  accent: blue[200],
  error: red[200],
  success: green[200],
}

export default borders
