import addAlpha from 'utils/add-alpha'
import { darkGray, blue, red, green } from '../colors'

const content = {
  primary: darkGray[10],
  secondary: addAlpha(darkGray[10], 0.6),
  tertiary: addAlpha(darkGray[10], 0.28),
  accent: blue[200],
  error: red[200],
  success: green[200],
}

export default content
