(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@emotion/core"), require("react-dom"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@emotion/core", "react-dom", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["@rapidapi/ui-lib"] = factory(require("react"), require("@emotion/core"), require("react-dom"), require("react-router-dom"));
	else
		root["@rapidapi/ui-lib"] = factory(root["react"], root["@emotion/core"], root["react-dom"], root["react-router-dom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__971__, __WEBPACK_EXTERNAL_MODULE__111__, __WEBPACK_EXTERNAL_MODULE__128__) {
return 