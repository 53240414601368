/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import React from 'react'
import { createPortal } from 'react-dom'
import { Box } from 'reflexbox'
import styled from 'themes'
import { SpaceProps, LayoutProps, variant } from 'styled-system'
import { Elevation } from 'components/data-display/elevation'
import useDialog from './use-dialog'

type DialogAnimation = 'none' | 'fade'

export type DialogProps = SpaceProps &
  LayoutProps & {
    open?: boolean
    disableBackdropClick?: boolean
    animation?: DialogAnimation
    onClose?: () => void
  }

const DialogStyled = styled(Box)<DialogProps>(
  {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 9000,

    '&:before': {
      position: 'fixed',
      content: '""',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: -1,
    },
  },
  () =>
    variant({
      prop: 'animation',
      variants: {
        none: {
          '&.open': {
            '&:before': {
              backgroundColor: 'rgba(0,0,0,0.5)',
            },
            '& .elevation': {
              opacity: 1,
            },
          },

          '&.close': {
            '&:before': {
              backgroundColor: 'rgba(0,0,0,0)',
            },
            '& .elevation': {
              opacity: 0,
            },
          },
        },
        fade: {
          '&.open': {
            '&:before': {
              animation: 'fade-in .15s ease',
              animationIterationCount: 1,
            },
            '& .elevation': {
              animation: 'drop-fade .35s cubic-bezier(0.4, 0.0, 1, 1)',
              animationIterationCount: 1,
            },
          },

          '&.close': {
            '&:before': {
              animation: 'fade-out .25s cubic-bezier(0.4, 0.0, 1, 1)',
              animationIterationCount: 1,
              animationDelay: '.2s',
            },
            '& .elevation': {
              animationIterationCount: 1,
              animation: 'ascend-fade .35s cubic-bezier(0.4, 0.0, 1, 1)',
            },
          },

          '@keyframes drop-fade': {
            '0%': {
              opacity: 0,
              transform: 'translateY(-10vh)',
            },
            '100%': {
              opacity: 1,
              transform: 'translateY(0)',
            },
          },

          '@keyframes ascend-fade': {
            '0%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
            '100%': {
              opacity: 0,
              transform: 'translateY(-40vh)',
            },
          },

          '@keyframes fade-in': {
            '0%': { backgroundColor: 'rgba(0,0,0,0)' },
            '100%': { backgroundColor: 'rgba(0,0,0,0.5)' },
          },

          '@keyframes fade-out': {
            '0%': { backgroundColor: 'rgba(0,0,0,0.5)' },
            '100%': { backgroundColor: 'rgba(0,0,0,0)' },
          },
        },
      },
    }),
)

const Dialog: React.FC<DialogProps> = ({
  open = false,
  maxWidth = 768,
  width = 'calc(100% - 160px)',
  height = 'calc(100% - 160px)',
  animation = 'fade',
  disableBackdropClick,
  children,
  onClose,
  ...other
}) => {
  const elementRef = document.createElement('div') as HTMLDivElement
  const { portalRoot, isVisible, ref } = useDialog({
    elementRef,
    open,
    onClose,
    disableBackdropClick,
  })

  if (!isVisible) {
    return null
  }

  return createPortal(
    <DialogStyled className={`${open ? 'open' : 'close'}`}>
      <Elevation
        p={5}
        size="large"
        display="flex"
        flexDirection="column"
        m={0}
        mt={80}
        {...{ ref, animation, maxWidth, width, height, ...other }}
      >
        {children}
      </Elevation>
    </DialogStyled>,
    portalRoot,
  )
}

export default Dialog
