/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { isValidElement, useCallback } from 'react'
import css from '@styled-system/css'
import { jsx } from '@emotion/core'
import { Flex, Box } from 'reflexbox'
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant as variantStyled,
  VariantArgs,
} from 'styled-system'
import styled from 'themes'
import { ContentColors } from 'themes/common.types'
import { Typography } from 'components/data-display/typography'
import { Icon } from 'components/data-display/icon'

export type TagProps = LayoutProps &
  SpaceProps &
  VariantArgs & {
    variant?: ContentColors | 'default'
    size?: 'default' | 'large'
    truncate?: number
    disabled?: boolean
    dismiss?: (event: React.MouseEvent<HTMLButtonElement>) => void
  }

const StyledTag = styled(Flex)(
  layout,
  space,
  ({ disabled, size, truncate }) =>
    css({
      // Padding combination for default/large size variant does not
      // currently exists in global theme.
      padding: size === 'large' ? '2px 8px' : '2px 6px',
      borderRadius: 'default',
      cursor: disabled ? 'not-allowed' : 'default',
      opacity: disabled ? 0.5 : 1,

      '& button': {
        height: size === 'large' ? 16 : 14,
        marginY: 0,
        marginLeft: 1,
        marginRight: 0,
        padding: 0,
        lineHeight: 0,
        bg: 'transparent',
        border: 'none',
        cursor: 'pointer',
      },
      '& button:focus, & button:active': {
        outline: '0 none',
      },
      '& button[disabled], & button:disabled': {
        cursor: 'not-allowed',
      },
      '&>.truncated>span': {
        maxWidth: truncate || 'auto',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    }),
  ({
    theme: {
      addAlpha,
      colors: { backgrounds, content, fills },
    },
  }) =>
    variantStyled({
      variants: {
        default: {
          color: addAlpha(content.tertiary, 0.6),
          bg: addAlpha(fills.tertiary, 0.12),
          '& button': {
            color: addAlpha(content.secondary, 0.6),
          },
        },
        primary: {
          color: 'content.accent',
          bg: addAlpha(backgrounds.info, 0.12),
          '& button': {
            color: 'content.accent',
          },
        },
      },
    }),
)

const Tag: React.FC<TagProps> = ({
  variant = 'default',
  size = 'default',
  disabled = false,
  truncate,
  dismiss,
  children,
  ...other
}) => {
  const disabledClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        e.preventDefault()
      }
    },
    [disabled],
  )
  return (
    <StyledTag
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      {...{ variant, size, disabled, truncate }}
      {...other}
    >
      {isValidElement(children) ? (
        children
      ) : (
        <Box className={truncate ? 'truncated' : undefined} as="span">
          <Typography
            variant={size === 'large' ? 'body1' : 'body2'}
            lineHeight={size === 'large' ? 'body1' : 'body2'}
            color="inherit"
            {...other}
          >
            {children}
          </Typography>
        </Box>
      )}
      {dismiss && (
        <button
          disabled={disabled}
          onClick={disabled ? disabledClick : dismiss}
          type="button"
          {...other}
        >
          <Icon
            color="inherit"
            size={size === 'large' ? 16 : 14}
            symbol="close"
          />
        </button>
      )}
    </StyledTag>
  )
}

export default Tag
