/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { memo, useMemo } from 'react'
import { jsx } from '@emotion/core'
import { Box } from 'reflexbox'
import styled from 'themes'
import {
  space as spaceStyled,
  SpaceProps,
  variant as variantStyled,
  VariantArgs,
} from 'styled-system'

export type TabItemButtonProps = SpaceProps &
  VariantArgs & {
    isCurrent: boolean
    variant?: 'tab' | 'segment'
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  }

const TabItemButton = styled.button<TabItemButtonProps>(
  spaceStyled,
  {
    fontFamily: 'default',
    outline: '0 none',
    border: 'none',
    background: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  ({
    theme: {
      addAlpha,
      typography,
      colors: { common, fills },
    },
  }) =>
    variantStyled({
      variants: {
        tab: {
          ...typography.caps,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: 'content.primary',
          borderBottom: 'disclosure',
          borderBottomColor: 'transparent',
          paddingX: 0,
          // paddingTop: '14px', // @TODO: confirm it with Pavel
          paddingBottom: '12px',
          '&.selected': {
            color: 'content.accent',
            borderBottomColor: 'borders.accent',
          },
        },
        segment: {
          ...typography.caps,
          padding: '1px 6px',
          minHeight: 20,
          color: 'content.primary',
          borderRadius: 'default',
          bg: addAlpha(fills.quanternary, 0),
          '&:hover:not(.selected)': {
            bg: addAlpha(fills.quanternary, 0.1),
          },
          '&.selected': {
            color: addAlpha(common.white, 0.87),
            bg: 'backgrounds.accent',
            borderBottom: 'none',
            borderBottomColor: 'none',
          },
          '&,&.selected,&:hover:not(.selected)': {
            transition:
              'background-color .1s cubic-bezier(0.4, 0.0, 1, 1), color .1s cubic-bezier(0.4, 0.0, 1, 1)',
          },
        },
      },
    }),
)

interface TabItemProps {
  tabName: string
  tabValue: string
  isCurrent: boolean
  variant?: 'tab' | 'segment'
  setCurrentTab: (tabValue: string) => void
}

const TabItemMemo = ({
  tabName,
  tabValue,
  isCurrent,
  setCurrentTab,
  variant = 'tab',
}: TabItemProps) => {
  const onClick = useMemo(
    () => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setCurrentTab(tabValue)
    },
    [setCurrentTab, tabValue],
  )

  return tabName.trim() !== '' ? (
    <Box as="li">
      <TabItemButton
        className={isCurrent ? 'selected' : ''}
        onClick={onClick}
        isCurrent={isCurrent}
        variant={variant}
        m={0}
      >
        {tabName}
      </TabItemButton>
    </Box>
  ) : (
    <Box as="span" />
  )
}

export const TabItem = memo(TabItemMemo)
