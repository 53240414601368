/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import styled from 'themes'
import { Box } from 'reflexbox'
import css from '@styled-system/css'
import {
  SwitchBase,
  SwitchBaseProps,
  SwitchBaseSize,
  getSwitchBaseSize,
} from '../switch-base'

const getSize = (size: SwitchBaseSize = 'default'): number =>
  size === 'large' ? 12 : 8

const SwitchBaseStyled = styled(SwitchBase)<SwitchBaseProps>(
  ({
    checked,
    disabled,
    size,
    theme: {
      addAlpha,
      colors: { blue, darkGray },
    },
  }) =>
    css({
      '.switch-base-input': {
        borderRadius: '50%',
        '&:focus-within,  &.focused': {
          borderRadius: '50%',
        },
      },
      '.switch-base-icon': {
        borderRadius: getSwitchBaseSize(size) / 2,
        bg: () => {
          if (disabled) {
            return addAlpha(darkGray[500], 0.08)
          }
          return checked ? addAlpha(blue[200], 0.12) : undefined
        },
        '>div': {
          width: getSize(size),
          height: getSize(size),
          borderRadius: '50%',
          bg: () => {
            if (disabled) {
              return 'fills.primary'
            }
            return checked ? 'backgrounds.accent' : 'backgrounds.primary'
          },
          borderColor: disabled ? 'fills.primary' : 'backgrounds.accent',
        },
      },
    }),
)

const RadioButton: React.FC<SwitchBaseProps> = (props) => (
  <SwitchBaseStyled type="radio" icon={<Box />} {...props} />
)

export default RadioButton
